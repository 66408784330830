import React from 'react'
import { cyberSafetyAssessmentHeadTitle } from '../../../helpers/constants';

const AssessmentHead = (props) => {
    const {currentQuestion, totalQuestions, totalAnsweredQuestions} = props;
    const progressBarStaus = (100/totalQuestions)*totalAnsweredQuestions;
    return (
        <div className='w-full space-y-5 font-Roboto'>
            <h1 className='text-base font-semibold text-simple-gray'>{cyberSafetyAssessmentHeadTitle}</h1>
            <div className='w-full space-y-10'>
                {currentQuestion !== totalQuestions && <h2 className='text-sm font-normal text-simple-gray'>{`Please answer the following ${totalQuestions} questions.`}</h2>}
                <div className='w-full space-y-2'>
                    <div className='w-full h-1.5 bg-input-border rounded-3xl'>
                        <div className={`h-full rounded-3xl bg-range-fill-bg transform duration-300`} style={{width:`${progressBarStaus}%`}} />
                    </div>
                    <h2 className='text-sm font-normal text-medium-gray'>{`Question ${totalAnsweredQuestions}/${totalQuestions} completed`}</h2>
                </div>
            </div>
        </div>
    )
}

export default AssessmentHead