import React from 'react'

function HeadingText({title, hasBorder=true}) {
    return (
        <div className={`pb-3.5 ${hasBorder ? 'border-b' : 'border-none'} border-lite-gray`}>
            <h1 className='font-semibold text-xl text-extra-dark-gray text-center leading-5 font-Gibson'>{title}</h1>
        </div>
    )
}

export default HeadingText