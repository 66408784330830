import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import api from '../../api/axiosConfig';
import { InnerHeader } from '../../components/common/inner-header';
import LoaderEllipse from '../../components/common/loader-ellipse';
import HeadingText from '../../components/common/heading-text';
import { breachesIntroduction, breachesSearchBtnText, breachesSearchInputCustValidationMsg, breachesSearchInputPlaceHolder, breachesTitle, defaultConnectionErrorMsg } from '../../helpers/constants';

const CheckForBreaches = () => {

    const navigate = useNavigate();
    const [isSearching, setIsSearching] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [hasError, setHasError] = useState(false);

    const handleChange = e => {
        setSearchText(e.target.value);
        setHasError(false);
    }

    const searchBreaches = (e) => {
        setIsSearching(true);
        e.preventDefault();

        api.post('/user/breaches', {
            requestData: searchText.trim()
        })
            .then(res => {
                if (res.status === 200) {
                    navigate(
                        '/check_for_breaches/results',
                        { state: { breaches: res.data.data } }
                    )

                }
            }).catch(err => {
                setHasError(true)
                setIsSearching(false);
                if (err && err.response && err.response.data.message) {
                    document.getElementById('error_response').innerText = err.response.data.message
                } else {
                    document.getElementById('error_response').innerText = defaultConnectionErrorMsg
                }
            })
    }

    useEffect(() => {
        window.scroll(0,0);
    }, [])

    return (
        <div className='w-full'>
            <div className='w-full'>
                <InnerHeader hasBackBtn={true} hasHomeBtn={true} />
            </div>
            <div className='flex justify-center w-full'>
                <div className='w-full max-w-4xl space-y-10 pb-8'>
                    <form onSubmit={searchBreaches}>
                        <HeadingText title={breachesTitle} />
                        <div className='w-full mt-7 text-sm text-simple-gray font-normal font-Roboto leading-5 space-y-6'>
                            {
                                Object.entries(breachesIntroduction).map((intro) => <p key={intro[0]}>{intro[1]}</p>)
                            }
                        </div>
                        <div className='w-full mt-8'>
                            <div className={`flex items-center space-x-2 border ${hasError ? 'border-err-input-border' : 'border-input-border'} rounded-lg`}>
                                <div className='pl-3'>
                                    <img className='mt-1' src='/images/breaches_search.png' alt='search_breaches' />
                                </div>
                                <input type="text" name="breachSearch" value={searchText}
                                    onChange={handleChange}
                                    placeholder={breachesSearchInputPlaceHolder}
                                    autoComplete='off'
                                    onInvalid={e => e.target.setCustomValidity(breachesSearchInputCustValidationMsg)}
                                    onInput={e => e.target.setCustomValidity('')}
                                    required
                                    className='w-full relative p-2 bg-app-bg text-simple-gray outline-none bg-transparent placeholder:text-medium-gray placeholder:text-sm placeholder:font-Roboto placeholder:font-normal' />
                            </div>
                            <div className='flex mt-1'>
                                <p className={`text-xs text-err-text ${hasError ? `block` : `hidden`}`} id="error_response"></p>

                            </div>

                        </div>
                        <div className='w-full flex justify-center mt-6'>
                            <button disabled={isSearching} type='submit' className={`flex justify-between items-center align-center text-sm font-semibold text-extra-dark-gray font-Roboto rounded-lg h-9 w-102px ${isSearching ? 'bg-range-fill-bg py-1 px-9' : 'bg-dark-yellow py-2 px-7'}`}>
                                {isSearching ? <LoaderEllipse /> : breachesSearchBtnText}
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}

export default CheckForBreaches