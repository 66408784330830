import React from 'react'

const GridWrapper = (props) => {
  return (
    <div className='w-full grid gap-5 grid-cols-1 sm:grid-cols-2'>
        {props.children}
    </div>
  )
}

export default GridWrapper