import React, { useEffect } from 'react'
import DynamicTitle from '../../components/common/dynamic-title'
import { InnerHeader } from '../../components/common/inner-header'
import ContentLlist from '../../components/cyber-training/content-list'
import { cyberSafetyDescription, cyberSafetyTitle } from '../../helpers/constants'

const CyberTraining = () => {
  useEffect(() => {
    window.scroll(0,0);
  }, [])
  return (
    <div className='w-full'>
      <div className='w-full'>
        <InnerHeader
          hasBackBtn={true}
          hasHomeBtn={true}
        />
        <DynamicTitle
          title={cyberSafetyTitle}
          hasBorder={true}
        />
      </div>
      <div className='w-full flex justify-center'>
        <div className='w-full max-w-4xl'>
          <div className='w-full space-y-10 py-8'>
            <div className='px-2'>
              <p className='text-sm font-normal text-simple-gray font-Roboto sm:text-center'>{cyberSafetyDescription} </p>
            </div>
            <ContentLlist />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CyberTraining