import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { localStorageUtils } from '../helpers/storageHelper';
import { boxxActivities, planCyberSafe, planEnhanced, planEssentials, urlPlanCyberSafe, urlPlanEnhanced, urlPlanEssentials } from '../helpers/constants';

const SubscribedRoute = ({ children }) => {

    const user = localStorageUtils.getLocalStorageItem('user');
    const location = useLocation();
    const currentActivity = Object.values(boxxActivities).find(activity => location.pathname.split('/').includes(activity.page_url.replace('/', '')));

    const url_plan = localStorageUtils.getLocalStorageItem('url_plan');
    let premium = null
    let policyPremium = []

    const premiumMap = {
        '39': '10K',
        '49': '25K',
        '69': '25K',
        '99': '50K',
        '199': '100K',
    };

    if (url_plan) {
        switch (url_plan) {
            case urlPlanEssentials:
                premium = planEssentials
                break;
            case urlPlanCyberSafe:
                premium = planCyberSafe
                break;
            case urlPlanEnhanced:
                premium = planEnhanced
                break;
            default:
                premium = null
                break;
        }
    }

    if (premium && user && user.policy) {
        policyPremium = user.policy.filter((policy) => policy.premium === premium);
        if (policyPremium.length) {
            policyPremium = policyPremium[0];
        }
    }
    if (user && user.policy && !policyPremium.length && !policyPremium.premium) {
        policyPremium = user.policy.reduce((maxPolicy, policy) => {
            const premiumValue = parseInt(policy.premium, 10);

            if (premiumValue > maxPolicy.premiumValue) {
                return {
                    premiumValue: premiumValue,
                    policyObject: policy
                };
            } else {
                return maxPolicy;
            }
        }, { premiumValue: -1, policyObject: null }).policyObject;
    }

    
    if (currentActivity && policyPremium && currentActivity.includedPolicy.includes(policyPremium.premium)) {
        currentActivity.insuranceCoverageAmount = premiumMap[policyPremium.premium] || '';
        localStorageUtils.setLocalStorageItem('insuranceCoverageAmount', currentActivity.insuranceCoverageAmount);
        return children;
    }

    return <Navigate to="/boxx" />
}

export default SubscribedRoute