import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import TagManager from 'react-gtm-module';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


if (process.env.REACT_APP_NODE_ENV === 'production') {
  TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID});
}
