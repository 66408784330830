import moment from 'moment';
import { breacheResultCardBtnText, breacheResultCardCompremisedDataLabel, breacheResultCardDateLabel } from '../../helpers/constants';

const Card = ({ data, handleLearnMore }) => {
    const formatedDate = moment(data.AddedDate).format('MMM DD, YYYY');
    return (
        <div className='w-full rounded-xl px-5 py-2.5 space-y-5 border-.5 border-dark-blue bg-light-blue font-Roboto '>
            <div className='space-y-1'>
                <h1 className='text-lg text-extra-dark-gray font-semibold '>{data.Name}</h1>
                <div className='space-y-1'>
                    <p className='mt-0 text-xs m-0 text-medium-gray '>{breacheResultCardDateLabel}</p>
                    <p className='mt-0 text-xs m-0 text-extra-dark-gray font-normal '>{formatedDate}</p>
                </div>
                <div className='space-y-1'>
                    <p className='mt-0 text-xs m-0 text-medium-gray font-normal'>{breacheResultCardCompremisedDataLabel}</p>
                    <p className='mt-0 text-xs m-0 text-extra-dark-gray '>{data.DataClasses.join(", ")}</p>
                </div>
            </div>
            <div className='w-full space-y-2'>
                <p className='text-xs font-normal text-dark-gray'>{ }</p>
                <div>
                    <div className='flex items-center space-x-3 cursor-pointer' onClick={() => handleLearnMore(data)}>
                        <p className='font-normal text-xs text-simple-gray uppercase '>{breacheResultCardBtnText}</p>
                        <img src={`/images/arrow-right-circle-small.png`} alt={'arrow circle'} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Card;