import React, { useState } from 'react'

///////-----Constants-----////////
import {
    draAssessment,
    draGrandTotal,
    draScoreStaringRange,
    draScoreChartOptions,
    draResultChartOptions,
    draChartData,
    draScoreChartWidth,
    draResultChartWidth,
    draInvalidSectionMsg
} from '../../helpers/constants'
import Assessment from './assessment';
import AssessmentResult from './assessment-result';
import AssessmentScore from './assessment-score';
import CyberSecurityReport from './cyber-security-report';

const SectionChooser = ({ section, handleContentChange }) => {

    const [currentSection, setCurrentSection] = useState(1);
    const [toggleValidatorMsg, setToggleValidatorMsg] = useState(false);
    //answer is in object formate (key : questionNumber, value : array with selected options id)
    const [answers, setAnswers] = useState({});
    const [totalMarkScored, setTotalMarkScored] = useState(0);
    const [userScoreRange, setUserScoreRange] = useState('');

    const totalQuestions = Object.values(draAssessment).length;
    let totalAnsweredQuestions = Object.values(answers).length;

    /**
     * 
     * @param answer is the key(or id) of the choosen answer 
     * @param answerType is the question having multilpe answers or only a single one('single' or 'multi').
     * @param currentQuestion is the key(or id) of the particular question  
     * @param answerTypeId is the id of the perticular input type
     */
    const handleAnswers = (answer, answerType, currentQuestion, answerTypeId) => {
        let tempAnswers = { ...answers };
        if (answerType === 'multi') {
            if (answers[currentSection]) {
                if (answers[currentSection][currentQuestion]) {
                    if (answers[currentSection][currentQuestion][answerTypeId]) {
                        if (tempAnswers[currentSection][currentQuestion][answerTypeId].includes(answer)) {
                            tempAnswers[currentSection][currentQuestion][answerTypeId].splice(tempAnswers[currentSection][currentQuestion][answerTypeId].indexOf(answer), 1)
                            if(tempAnswers[currentSection][currentQuestion][answerTypeId].length === 0){
                               delete tempAnswers[currentSection][currentQuestion][answerTypeId] 
                            }
                            if(Object.values(tempAnswers[currentSection][currentQuestion]).length === 0){
                               delete tempAnswers[currentSection][currentQuestion]
                            }
                            if(Object.values(tempAnswers[currentSection]).length === 0){
                               delete tempAnswers[currentSection]
                            }
                        } else {
                            tempAnswers[currentSection][currentQuestion][answerTypeId].push(answer)
                        }
                    } else {
                        tempAnswers[currentSection][currentQuestion][answerTypeId] = [answer]
                    }
                } else {
                    tempAnswers[currentSection][currentQuestion] = {}
                    tempAnswers[currentSection][currentQuestion][answerTypeId] = [answer]
                }
            } else {
                tempAnswers[currentSection] = {}
                tempAnswers[currentSection][currentQuestion] = {}
                tempAnswers[currentSection][currentQuestion][answerTypeId] = [answer]
            }
        } else {
            if (answerType === 'single') {
                if (answers[currentSection]) {
                    if (answers[currentSection][currentQuestion]) {
                        if (answers[currentSection][currentQuestion][answerTypeId]) {
                            tempAnswers[currentSection][currentQuestion][answerTypeId][0] = answer
                        } else {
                            tempAnswers[currentSection][currentQuestion][answerTypeId] = [answer]
                        }

                    } else {
                        tempAnswers[currentSection][currentQuestion] = {}
                        tempAnswers[currentSection][currentQuestion][answerTypeId] = [answer]
                    }
                } else {
                    tempAnswers[currentSection] = {}
                    tempAnswers[currentSection][currentQuestion] = {}
                    tempAnswers[currentSection][currentQuestion][answerTypeId] = [answer]
                }
            }
        }
        setAnswers(tempAnswers)
    }


    /**
     * 
     * @param operaton indicates next or preview
     */
    const handleQuestions = (operaton) => {
        if (operaton === 'previous' && setCurrentSection !== 1) {
            setCurrentSection((prevState) => prevState - 1)
            return
        }

        // current section is not in answers.
        // current section is there and current question is not in answers
        if (!answers[currentSection] || 
            Object.values(answers[currentSection]).length !== Object.values(draAssessment[currentSection].questions).length ) 
            {
            setToggleValidatorMsg(true)
            let toggleTimer = setTimeout(() => {
                setToggleValidatorMsg(false)
                clearTimeout(toggleTimer)
            }, 2000);
            return
        }
        if (answers[currentSection] || operaton === 'previous') {
            if (operaton === 'previous' && setCurrentSection !== 1) {
                setCurrentSection((prevState) => prevState - 1)
            } else {
                currentSection !== Object.values(draAssessment).length ? setCurrentSection((prevState) => prevState + 1) : handleResult()
            }
        }
    }

    const handleResult = () => {
        let totalMark = 0;
        let answeredMarks = 0;
        let defaultMarks = 0;
        let draAssessmentLength = Object.values(draAssessment).length
        for (let section = 1; section <= draAssessmentLength; section++) {
            let draAssessmentQuestionsLength = Object.values(draAssessment[section].questions).length
            for (let question = 1; question <= draAssessmentQuestionsLength; question++) {
                let draAssessmentOptionTypeLength = Object.values(draAssessment[section].questions[question].answerTypes).length
                for (let answertype = 1; answertype <= draAssessmentOptionTypeLength; answertype++) {
                    let draAssessmentOptionsLength = Object.values(draAssessment[section].questions[question].answerTypes[answertype].options).length
                    for (let option = 1; option <= draAssessmentOptionsLength; option++) {
                        if(answers[section][question][answertype]){
                            if ((answers[section][question][answertype]).includes(`${option}`)) {
                                answeredMarks = answeredMarks + draAssessment[section].questions[question].answerTypes[answertype].options[option].marks.selected
                            } else if (draAssessment[section].questions[question].answerTypes[answertype].options[option].marks.blank > 0) {
                                defaultMarks = defaultMarks + draAssessment[section].questions[question].answerTypes[answertype].options[option].marks.blank
                            }
                        }
                    }
                }
            }
        }
        totalMark = answeredMarks + defaultMarks
        setTotalMarkScored(totalMark > 0 ? totalMark : '0')

        setUserScoreRange(totalMark <= 50 ? `POOR` : (totalMark <= 84 && totalMark > 49) ? `GOOD` : totalMark >= 85 && `GREAT`)

        // // here set sore component active
        handleContentChange('assessment score', 'forward')
    }

    switch (section) {
        case 'assessment':
            return (
                <Assessment
                    assessments={draAssessment}
                    currentSection={currentSection}
                    totalAnsweredQuestions={totalAnsweredQuestions}
                    totalQuestions={totalQuestions}
                    answers={answers}
                    handleAnswers={handleAnswers}
                    handleQuestions={handleQuestions}
                    toggleValidatorMsg={toggleValidatorMsg}
                />
            )
        case 'assessment score':
            return (
                <AssessmentScore
                    chartData={draChartData}
                    chartOptions={draScoreChartOptions}
                    totalMarkScored={totalMarkScored}
                    userScoreRange={userScoreRange}
                    handleContentChange={handleContentChange}
                    grandTotal={draGrandTotal}
                    scoreStaringRange={draScoreStaringRange}
                    scoreChartWidth={draScoreChartWidth}
                />
            )
        case 'assessment result':
            return (
                <AssessmentResult
                    chartData={draChartData}
                    chartOptions={draResultChartOptions}
                    totalMarkScored={totalMarkScored}
                    userScoreRange={userScoreRange}
                    handleContentChange={handleContentChange}
                    grandTotal={draGrandTotal}
                    scoreStaringRange={draScoreStaringRange}
                    resultChartWidth={draResultChartWidth}
                />
            )
        case 'cyber security report':
            return (
                <CyberSecurityReport
                    handleContentChange={handleContentChange}
                />
            )

        default: return <h1 className='font-semibold font-Roboto text-extra-dark-gray'>{draInvalidSectionMsg}</h1>
    }
}

export default SectionChooser