import React, { useEffect, useState } from 'react'
import api from '../api/axiosConfig';
import BoxxaActivities from '../components/boxx-page/boxx-activities';
import { InnerHeader } from '../components/common/inner-header';
import SideNav from '../components/navbar/sideNav';
import { localStorageUtils } from '../helpers/storageHelper';
import HeadingText from '../components/common/heading-text';

//---Constants---//
import { homePageTitle, homePageTitleDescription } from '../helpers/constants';

const Boxx = () => {

  const [isSideNav, setIsSideNav] = useState(false)
  const [user, setUser] = useState(null);

  const toggleSideNav = () => {
    setIsSideNav(!isSideNav);
  }

  useEffect(() => {
    document.body.style.overflow = isSideNav ? "hidden" : "auto";
  }, [isSideNav])

  useEffect(() => {
    const fetchedUser = localStorageUtils.getLocalStorageItem('user');
    if (!fetchedUser) {
      api.get('/user/profile')
        .then(res => {
          if (res.status === 200) {
            localStorageUtils.setLocalStorageItem('user', res.data.data.user);
            setUser(res.data.data.user);
          }
        }).catch(err => {
          console.log(err)
        })
    } else {
      setUser(fetchedUser);
    }
  }, [])

  useEffect(() => {
    window.scroll(0,0);
  }, [])

  return (
    <div className='w-full relative pb-10 px-0 lg:px-7'>
      <div className='block md:hidden w-full'>
        <InnerHeader hasBackBtn={false} />
      </div>
      <div className='block md:hidden'>
        <HeadingText title={homePageTitle} hasBorder={false} />
      </div>
      <div className='w-full md:flex hidden flex-col items-center md:space-y-10 md:py-14 md:mb-0'>
        <div className='text-extra-dark-gray md:text-4xl text-2xl font-semibold font-Gibson sm:text-center text-left'>
          <h1>{homePageTitle}</h1>
        </div>
        <div className='md:text-xl.5 text-xl.5 leading-7 text-dark-gray font-Roboto sm:text-center text-left max-w-2xl lg:max-w-3xl'>
          <p>
            {homePageTitleDescription}
          </p>
        </div>
      </div>
      <div className='w-full md:hidden flex flex-col items-center space-y-5 pb-5 mb-10 border-b border-extra-lite-gray text-sm text-simple-gray font-normal font-Roboto'>
        <p>{homePageTitleDescription}</p>
      </div>
      <BoxxaActivities user={user}/>
      <SideNav
        isSideNav={isSideNav}
        toggleSideNav={toggleSideNav}
      />
    </div>
  )
}

export default Boxx