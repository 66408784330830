import React, { useEffect } from 'react'
import DynamicInnerPageWrapper from '../../components/cyber-training/dynamic-inner-page-wrapper'
import { sensitiveData } from '../../data/sensitive-data'

const SensitiveData = () => {
  useEffect(() => {
    window.scroll(0,0);
  }, [])
  const data = sensitiveData;
  return (
    <div className='w-full'>
      <DynamicInnerPageWrapper
        data={data}
      />
    </div>
  )
}

export default SensitiveData