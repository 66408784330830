import React from 'react'
import { formPopupValidationMsg } from '../../../helpers/constants';
import CustomFormValidatorMsg from '../../common/cust-form-validator-msg';

const QuestionCard = (props) => {
    const { handleAnswers, answers, currentQuestion, currentAssessment, toggleValidatorMsg } = props;
    return (
        <div className='w-full space-y-4 font-Roboto'>
            <h1 className='text-base font-normal text-simple-gray'>{currentAssessment?.question ?? ""}</h1>
            <div className='space-y-3 relative'>
                {Object.entries(currentAssessment?.options).map((option) => (
                    <div className='flex items-center space-x-3' key={option[0]}>
                        <input type="radio" name={currentQuestion} id={`assment-rad-${currentQuestion}-${option[0]}`} onChange={()=>handleAnswers(option[0])} checked={answers[currentQuestion] === option[0] ? true : false} className='custom-radio-btn' />
                        <label htmlFor={`assment-rad-${currentQuestion}-${option[0]}`} className='text-sm font-normal text-dark-gray cursor-pointer'>{option[1]}</label>
                    </div>
                ))}
                <CustomFormValidatorMsg
                    message={formPopupValidationMsg}
                    isToggle={toggleValidatorMsg}
                />
            </div>
        </div>
    )
}

export default QuestionCard