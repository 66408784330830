import React from 'react'

const LoaderEllipse = () => {
  return (
    <div className='animate-spin inline-block rounded-full'>
        <img src="/images/Ellipse 1.png" alt="loader-ellipse" />
    </div>
  )
}

export default LoaderEllipse