import React from 'react'

const DynamicBanner = (props) => {
  const { image, header } = props;
  const backgroundImage = image;
  return (
    <div className={`h-28 w-full ${backgroundImage} flex items-center justify-center bg-repeat absolute`}>
      <h1 className='text-2xl font-normal leading-8 font-Gibson text-white'>{header}</h1>
    </div>
  )
}

export default DynamicBanner