import React from 'react'
import { cyberSafetyAssessmentTryagainMsg } from '../../../helpers/constants';
import FailedIcon from '../../common/failed-icon'
import SuccessIcon from '../../common/success-icon'

const ResultWithMsg = (props) => {
    const { isPass, resultMsg, description } = props;
    return (
        <div className='w-full flex flex-col items-center font-Roboto'>
            <div className='w-full flex flex-col items-center space-y-6'>
                {isPass ?
                    <SuccessIcon />
                    :
                    <FailedIcon />
                }
                <div className=' text-center max-w-sm text-extra-dark-gray space-y-3'>
                    <div className='font-semibold text-lg leading-7'>
                        <h1 className=''>{resultMsg}</h1>
                        {!isPass && <h1 className=''>{cyberSafetyAssessmentTryagainMsg}</h1>}
                    </div>
                    <p className='text-sm text-dark-gray font-normal'>{description}</p>
                </div>
            </div>
        </div>
    )
}

export default ResultWithMsg