export const localStorageUtils = {
    getLocalStorageItem: (key) => {
        let value = localStorage.getItem(key);
        try {
            value = JSON.parse(value);
        } catch (e) {
            // Error
        }
        return value;
    },
    setLocalStorageItem: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    },
    removeLocalStorageItem: (key) => {
        localStorage.removeItem(key);
    }
}

export const cookieStorageUtils = {
    deleteCookie: (key) => {
        document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    },
    getCookie: (key) => {
        let cookie = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
        if (cookie) {
            return true;
        }
        return false;
    },
    getCookies: () => {
        const sessionCookie = document.cookie;
        const cookies = sessionCookie.split("; ").reduce((acc, cookie) => {
            const [name, value] = cookie.split('=');
            try {
                acc[name] = JSON.parse(value);
            } catch (e) {
                acc[name] = value;
            }
            return acc;
        }, {})
        return cookies;
    }
}