import React from 'react'
import { useNavigate } from "react-router-dom";
import { authUtils } from '../../helpers/authHelper';
import { localStorageUtils } from '../../helpers/storageHelper';

const SideNav = (props) => {
    const { isSideNav, toggleSideNav } = props;
    const navigate = useNavigate()
    const user = localStorageUtils.getLocalStorageItem('user');
    const logout = () => {
        toggleSideNav();
        authUtils.handleLogout();
        navigate('/')
    }
    return (
        <div className={`w-72 z-50 h-screen transform duration-500 font-Roboto ${isSideNav ? `translate-x-0` : `translate-x-full`} pl-10 pr-5 space-y-10 fixed top-0 right-0 bg-medium-white`}>
            <div className='w-full flex items-center justify-end py-5'>
                <button onClick={() => toggleSideNav()} className='flex items-center space-x-2.5'>
                    <div className='flex items-center min-w-max'>
                        <img className='h-5 w-5' src="/images/x-circle-new.png" alt="close-btn" />
                    </div>
                    <p className='text-sm font-normal text-simple-gray font-Roboto'>Close</p>
                </button>
            </div>
            <div className='w-full space-y-8'>
                <div className='w-full flex'>
                    <div className='min-w-max'>
                        <img className='w-8 h-8' src="/images/user-profile-icon.png" alt="profile img" />
                    </div>
                    <div className='w-full space-y-2 pl-4'>
                        <h1 className='text-sm font-normal text-extra-dark-gray'>{user && user.fullName ? user.fullName : 'Profile details'}</h1>
                        <div className='w-full'>
                            <p className='text-dark-gray font-normal text-sm'>{user && user.phone ? user.phone : '(000)- 000-0000'}</p>
                        </div>
                    </div>
                </div>
                <button className='w-full flex items-center max-w-min' onClick={logout}>
                    <div className='w-8 h-8 flex items-center'>
                        <img src="/images/log-out.png" alt="logout" />
                    </div>
                    <div className='w-full pl-4'>
                        <h1 className='text-sm font-normal text-extra-dark-gray min-w-max'>Log out</h1>
                    </div>
                </button>
            </div>
        </div>
    )
}

export default SideNav