export const onlineRisksData = {
    pathName: 'online-risks',
    dynamicBanner: {
      img: 'bg-online_risks',
      title: 'Managing Online Risks'
    },
    introduction: {
      description: 'When spending an increased amount of time online, it is important to understand what the online risks are and what you can do to avoid being successfully attacked.',
      moduleDescription: 'Learn more about how to manage risks online including:',
      modules: ['Phishing ', 'Social Engineering'],
      guideLineContent: 'This module includes an educational video, downloadable information resources and an assessment (15 questions).'
    },
    eLearning: {
      description: `Please view the module's introductory video as well as the e-learning material.`,
      fileContent: [
        {
          title: 'PHISHING KEY POINTS',
          file: 'BOXX Academy - Phishing EN.pdf'
        },
        {
          title: 'SOCIAL ENGINEERING KEY POINTS',
          file: 'BOXX Academy - Social Engineering.pdf'
        },
      ],
      video: 'https://player.vimeo.com/video/814950203?h=ddb11f0b33'
    },
    assessment: {
      questions: {
        1: {
          question: "What term describes an attempt to obtain sensitive information by sending an email that appears to come from a well-known website?",
          options: {
            1: "Social engineering",
            2: "Identity theft",
            3: "Phishing",
            4: "Spam",
          },
        },
        2: {
          question: "What is the MOST serious impact of installing malicious code on your computer?",
          options: {
            1: "Criminals may gain access to information stored on my computer",
            2: "I may have to restore data from a backup",
            3: "Shopping websites may encourage me to spend more on each purchase",
            4: "Programs might stop working",
          },
        },
        3: {
          question: "What does a typical phishing email often contain?",
          options: {
            1: "Complicated information",
            2: "Text in a foreign language",
            3: "An offer of a prize or free gift",
            4: "Information that is automatically blocked",
          },
        },
        4: {
          question: "Who is the target of spear phishing?",
          options: {
            1: "Specific individuals",
            2: "Random individuals",
            3: "Young individuals",
            4: "Elderly individuals",
          },
        },
        5: {
          question: "You receive a phone call from someone claiming there is a problem with your computer. They ask if they can install a diagnostic program. What should you do?",
          options: {
            1: "Ask the caller to wait while I back up my files on my computer",
            2: "Ask the caller for proof of identity and details of their company",
            3: "Ask the caller to ring back at a more convenient time",
            4: "Ask the caller to install the program as soon as possible",
          },
        },
        6: {
          question: "What should you do if you receive a call from your bank asking you to confirm your personal information?",
          options: {
            1: `Give them my details as they are working for the bank`,
            2: "Decline the request and call the bank myself to verify/report the request",
            3: "Hang up immediately",
            4: "Ask the caller to provide their name and role before giving my details",
          },
        },
        7: {
          question: "Which is a risk associated with a social engineering attack?",
          options: {
            1: "I won't be able to access my social networking sites",
            2: "The security patches for my software won't be kept up to date",
            3: "Criminals will be able to gain unauthorized access to personal and financial information",
            4: "I won't be able to open attachments that are included in emails",
          },
        },
        8: {
          question: "What should you do to reduce the risk of a social engineering attack?",
          options: {
            1: "I should open unfamiliar email attachments to check the content is safe",
            2: "I should make sure all my information is stored on a central server",
            3: "I should never take company work home to do in the evenings",
            4: "I should check the email sender's address to be sure all sensitive emails are being sent to the correct individual",
          },
        },
        9: {
          question: "Which is one of the ways to check that emails you receive are genuine?",
          options: {
            1: "Reply to the email to check that the sender is genuine",
            2: "Forward the email to yourself before replying",
            3: "Respond using a different email",
            4: "Check the sender's address is one that you recognize",
          },
        },
        10: {
          question: "Which is an example of baiting in a social engineering attack?",
          options: {
            1: "Someone following me through security access control",
            2: "A no-reply email from a business communicating policy updates",
            3: "Someone calling my phone pretending there's been a virus attack",
            4: "An email from an unknown source telling me I've won a competition",
          },
        },
        11: {
          question: "Which is an objective of payment diversion fraud?",
          options: {
            1: "To obtain access to an individual's bank account",
            2: "To convince someone to willingly process fake payment",
            3: "To obtain access to an individual's email",
            4: "To instruct a payment from an individual into an account controlled by fraudsters using falsified payment details",
          },
        },
        12: {
          question: "You receive a phone call from a business you don't recognize. They are asking for an invoice number. What should you do to verify the request?",
          options: {
            1: "Provide the reference number. It is only an internal reference so therefore not confidential",
            2: "Ask them to send you an e-mail so you can further verify their credentials before taking further action",
            3: "Ask them for their manager",
            4: "Immediately put the phone down",
          },
        },
        13: {
          question: "What should you do before giving out personal information to others via phone or e-mail?",
          options: {
            1: "Give them the contact details of another individual or family member who might be able to help",
            2: "Verify the person is who they say they are, and that the request is legitimate",
            3: "Nothing, if they have your contact details they must be legitimate",
            4: "Ask them to confirm their name and phone number",
          },
        },
        14: {
          question: "Which of the following signs in an e-mail may indicate an attempt at payment diversion fraud?",
          options: {
            1: "You are being asked to send across your banking details from someone you were not anticipating",
            2: "The language that is used seems unusual",
            3: "You are being asked to make a payment using a method you are not aware of",
            4: "All of the above",
          },
        },
        15: {
          question: "Which would be the key indicator that an e-mail from someone may not be genuine?",
          options: {
            1: "There isn't a company logo in the body of the e-mail",
            2: "The e-mail domain is different from e-mails you have previously received from that contact or organization",
            3: "There is no phone number in the e-mail signature",
            4: "There is a spelling mistake in the e-mail",
          },
        },
      },
      passLimit: 10,
      answerKey: {
        1: 3,
        2: 1,
        3: 3,
        4: 1,
        5: 2,
        6: 2,
        7: 3,
        8: 4,
        9: 4,
        10: 4,
        11: 4,
        12: 2,
        13: 2,
        14: 4,
        15: 2,
      }
    }
  }