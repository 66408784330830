export const cyberSecurityData = {
    pathName: 'cyber-security',
    dynamicBanner: {
      img: 'bg-wfh',
      title: 'Cybersecurity on the Go '
    },
    introduction: {
      description: 'Being connected on the go can make you more vulnerable to cyberattacks on public Wi-Fi networks or the targets of hardware theft. That’s why it’s important to be digitally vigilant when you’re on the go, even if it’s in a neighborhood coffee shop, as cybercriminals are looking to capture sensitive data of the people that use them. ',
      moduleDescription: 'This training module will teach you how to reduce online risks when you’re on the go.',
      modules: [],
      guideLineContent: 'This module includes an educational video, downloadable information resources and an assessment (15 questions).'
    },
    eLearning: {
      description: `Please view the module's introductory video as well as the e-learning material.`,
      fileContent: [
        {
          title: 'PASSWORD SAFETY KEY POINTS',
          file: 'BOXX Academy - Password Safety.pdf'
        },
        {
          title: 'REMOVABLE MEDIA KEY POINTS',
          file: 'BOXX Academy - Removable Media.pdf'
        },
      ],
      video: 'https://player.vimeo.com/video/814949630?h=14756a54da'
    },
    assessment: {
      questions: {
        1: {
          question: "Why is it important to understand the threats to your device, and their possible impact?",
          options: {
            1: "To know when I should stop using my device",
            2: "To know how much insurance I should have for my device",
            3: "To make decisions about how to protect my device",
            4: "To make decisions about when to replace my device",
          },
        },
        2: {
          question: "Downloading applications, software, music etc. from a reputable source...",
          options: {
            1: "reduces the risk of an attacker stealing my information.",
            2: "is a waste of money.",
            3: "can attract the attention of an attacker who wants to intercept my download.",
            4: "offers no additional security to my device or data.",
          },
        },
        3: {
          question: "You want to understand how to use a device securely and ethically. Where should you look FIRST?",
          options: {
            1: "My company's records management policy",
            2: "My employment contract with my company",
            3: "My company's acceptable use policy",
            4: "My company's identity and access management policy",
          },
        },
        4: {
          question: "Which is the strongest password?",
          options: {
            1: "B@n@na$",
            2: "CB@n@n@$ ",
            3: "Bananas",
            4: "@Bananas",
          },
        },
        5: {
          question: "Why should you use different passwords for each online account?",
          options: {
            1: "To make it hard to remember the passwords",
            2: "Because different accounts have different password lengths",
            3: "To make the passwords harder to guess",
            4: "Because if one password is stolen, a hacker cannot use it to access other accounts",
          },
        },
        6: {
          question: "What is a virtual private network (VPN) used for?",
          options: {
            1: `Sending emails and browsing the web`,
            2: "Quickly downloading patches to my mobile device",
            3: "Creating a secure link with my company network",
            4: "Allowing my company to monitor my activity",
          },
        },
        7: {
          question: "You need to store sensitive company information on your encrypted laptop.  What should you do to protect this data?",
          options: {
            1: "I don't need to do anything, because encryption will protect the sensitive data",
            2: "I should send a copy of the data to my work email whenever I make a change, so it can't be lost",
            3: "I should delete the data from the company network, so there are no conflicting versions",
            4: "I should delete all local copies of the sensitive data as soon as I have finished using it",
          },
        },
        8: {
          question: "What should you do when using your mobile device to work remotely in a public place?",
          options: {
            1: "Keep my device in clear view even when I'm not using it ",
            2: "Connect my device to the fastest available Wi-Fi network",
            3: "Ensure that confidential information cannot be viewed by those around me",
            4: "Use a single password for all the systems and software on my device",
          },
        },
        9: {
          question: "How can you make sure that all data moving to or from your mobile device is private and secure?",
          options: {
            1: "I should encrypt all data on my device",
            2: "I should install patches on my device as soon as they are available",
            3: "I should use a private VPN whenever I connect to the internet",
            4: "I should only use company apps on my device, not personal ones",
          },
        },
        10: {
          question: "You are outside of your home and need to send a large amount of sensitive information to a family member quickly. What is the BEST action to take?",
          options: {
            1: "I should find the nearest public Wi-Fi connection and send the information",
            2: "I should connect to any WiFi connection, but check that no one can see my screen when sending the information",
            3: "I should use a strong WiFi connection, but divide the information into small amounts before sending it",
            4: "I should connect to a trusted Wi-Fi network and send the information using my private VPN",
          },
        },
        11: {
          question: "If you receive an e-mail from a contact and you suspect their account has been compromised, what would be the best course of action to take?",
          options: {
            1: "Ignore them",
            2: "Forward on the e-mail to someone who works in IT",
            3: "Contact them as soon as possible via phone or in person to share your suspicions",
            4: "Reply to the e-mail to verify the request",
          },
        },
        12: {
          question: "Why might it raise suspicions if you receive an urgent e-mail from an executive in your organization asking for sensitive information, and that you keep their request confidential?",
          options: {
            1: "If you don’t typically receive requests of this nature",
            2: "Because you are being pressured by the urgency of the e-mail",
            3: "Because you are being asked to keep the request confidential",
            4: "All of the above",
          },
        },
        13: {
          question: "How does multi-factor authentication help protect your e-mail accounts?",
          options: {
            1: "It doesn’t",
            2: "It helps to prevent phishing e-mails being received",
            3: "When attempting to log into your e-mail account, as well as your password, access to second 'factor' is required, for example a unique code sent via SMS to your mobile phone",
            4: "It protects your computer from viruses",
          },
        },
        14: {
          question: "If you receive an e-mail from a known contact of yours which uses language that seems odd and where they are asking for sensitive information, why might this pose a risk to you and your organization?",
          options: {
            1: "It may be a fraudster who has accessed the account, and is trying to obtain the information for malicious purposes",
            2: "Because all e-mails that ask for sensitive information pose a significant risk",
            3: "Because it will help to stop you receiving phishing e-mails",
            4: "It will help protect your computer against viruses",
          },
        },
        15: {
          question: "Why is it important to use strong password to protect your e-mail accounts?",
          options: {
            1: "It isn't that important as long as you check your e-mails regularly",
            2: "To make it more difficult for anyone else to gain unauthorized access",
            3: "Because it will help to stop you receiving phishing e-mails",
            4: "It will help protect your computer against viruses",
          },
        },
      },
      passLimit: 10,
      answerKey: {
        1: 3,
        2: 1,
        3: 3,
        4: 2,
        5: 4,
        6: 3,
        7: 4,
        8: 3,
        9: 4,
        10: 4,
        11: 3,
        12: 4,
        13: 3,
        14: 2,
        15: 2,
      }
    }
  }