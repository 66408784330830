import React from 'react'
import { formPopupValidationMsg } from '../../helpers/constants';
import Generator from '../../helpers/generator';
import CustomFormValidatorMsg from '../common/cust-form-validator-msg';

const QuestionCard = (props) => {
    const { handleAnswers, answers, currentQuestion, sectionId, currentAssessment, toggleValidatorMsg } = props;
    return (
        <div className='w-full space-y-4 font-Roboto'>
            <h1 className='text-base font-normal text-simple-gray'>{currentAssessment?.question ?? ""}</h1>
            <div className={`w-full pb-2 relative`}>
                <div className='w-full space-y-8'>
                    {
                        currentAssessment.answerTypes &&
                        Object.entries(currentAssessment.answerTypes).map((inpt) => (
                            <Generator
                                key={`${sectionId}-${currentQuestion}-${inpt[0]}`}
                                inputType={inpt[1].answerType ?? ''}
                                data={inpt[1].options ?? {}}
                                questionId={currentQuestion}
                                sectionId={sectionId}
                                answerTypeId={inpt[0]}
                                handleAnswers={handleAnswers}
                                answers={answers}
                            />
                        ))
                    }
                </div>
                {(!answers[sectionId] || !answers?.[sectionId][currentQuestion] || Object.values(answers?.[sectionId]?.[currentQuestion]).length === 0) &&
                    <CustomFormValidatorMsg
                        message={formPopupValidationMsg}
                        isToggle={toggleValidatorMsg}
                    />
                }
            </div>
        </div>
    )
}

export default QuestionCard