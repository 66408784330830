import React from 'react'

const CustomFormValidatorMsg = ({ message, isToggle }) => {

    return (
        <div className={`absolute top-full -left-3 transform duration-700 cursor-default ${isToggle ? `opacity-100` : `opacity-0`}`}>
            <div className={`flex items-center relative space-x-2 border border-input-border rounded-md px-2 py-1`}>
                <div className='flex items-center justify-center h-4 w-4 rounded-sm bg-dark-yellow'>
                    <p className='text-sm text-white'>!</p>
                </div>
                <p className='text-xs font-normal text-medium-gray'>{message}</p>
                <div className='border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-input-border absolute bottom-full left-0 0'>
                    
                </div>
            </div>
        </div>
    )
}

export default CustomFormValidatorMsg