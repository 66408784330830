import { cookieStorageUtils, localStorageUtils } from "./storageHelper";

export const authUtils = {
    handleLogout: () => {
        localStorageUtils.removeLocalStorageItem('user');
        localStorageUtils.removeLocalStorageItem('token');
        localStorageUtils.removeLocalStorageItem('refreshToken');
        cookieStorageUtils.deleteCookie('authToken');
        cookieStorageUtils.deleteCookie('refreshToken');
    },
    isAuthenticated: () => {
        const isAuthenticated = localStorageUtils.getLocalStorageItem('token');
        return isAuthenticated;
    }
}