import React from 'react'
import { InsuranceCoverageBajajAllianzLinkText, InsuranceCoverageBajajAllianzPageLink } from '../../helpers/constants'

const Footer = () => {
    return (
        <div className='w-full flex justify-center'>
            <div className='w-full py-5 max-w-4xl space-y-5'>
                <div className=''>
                    <img src="/images/bajajallianz_logo.webp" alt="bajajallianz logo" />
                </div>
                <div className='space-y-3'>
                    <div>
                        <a href={InsuranceCoverageBajajAllianzPageLink} target="_blank" rel="noreferrer">
                            <div className='flex items-center space-x-3 '>
                                <h1 className='text-sm text-simple-gray font-normal font-Roboto'>{InsuranceCoverageBajajAllianzLinkText}</h1>
                                <img src="/images/arrow-right-circle-small_v1.svg" alt="arrow" />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer