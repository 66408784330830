import React, { useEffect } from 'react'
import DynamicInnerPageWrapper from '../../components/cyber-training/dynamic-inner-page-wrapper'
import { onlineRisksData } from '../../data/online-risks-data'

const OnlineRisks = () => {
  useEffect(() => {
    window.scroll(0,0);
  }, [])
  const data = onlineRisksData;
  return (
    <div className='w-full'>
      <DynamicInnerPageWrapper
        data={data}
      />
    </div>
  )
}

export default OnlineRisks