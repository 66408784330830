import React from 'react'
import { Link } from 'react-router-dom';
import { cyberSafetyAssessmentBackToTrainingBtnText, cyberSafetyAssessmentRestartBtnText } from '../../../helpers/constants';

const ResultPageBtns = (props) => {
    const { isPass, handleRestartAssessment } = props;
    return (
        <div className={`w-full`}>
            {isPass ?
                <div className='w-full flex justify-center font-Roboto'>
                    <Link to={'/cyber_training'}>
                        <div className='yellow-bg-btn'>{cyberSafetyAssessmentBackToTrainingBtnText}</div>
                    </Link>
                </div>
                :
                <div className='w-full flex flex-col items-center space-y-3'>
                    <button onClick={() => handleRestartAssessment()} className='yellow-bg-btn'>{cyberSafetyAssessmentRestartBtnText}</button>
                    <Link to={'/cyber_training'}>
                        <div className='px-7 py-2 rounded-lg text-strong-gray text-sm border border-dark-yellow font-medium'>{cyberSafetyAssessmentBackToTrainingBtnText}</div>
                    </Link>
                </div>
            }
        </div>
    )
}

export default ResultPageBtns