import React, { useEffect } from 'react'
import DynamicTitle from '../../components/common/dynamic-title'
import { InnerHeader } from '../../components/common/inner-header'
import Introduction from '../../components/digital-risk-assessment/introduction'
import { draPageTitle } from '../../helpers/constants'


const DigitalRiskAssessment = () => {
    useEffect(() => {
        window.scroll(0,0);
    }, [])
    return (
        <div className='w-full'>
            <div className='w-full'>
                <InnerHeader
                    hasBackBtn={true}
                    hasHomeBtn={true}
                />
                <DynamicTitle
                    title={draPageTitle}
                    hasBorder={true}
                />
            </div>
            <div className='w-full flex flex-col items-center'>
                <div className='w-full max-w-4xl '>
                    <Introduction />
                </div>
            </div>
        </div>
    )
}

export default DigitalRiskAssessment