import React from 'react'
import { Navigate } from 'react-router-dom'
import LoginForm from '../components/login/loginForm'
import { authUtils } from '../helpers/authHelper'

const Login = () => {

  if (authUtils.isAuthenticated()) {
    console.log('Is authenticated')
    return <Navigate to='/boxx' />
  }
  return (
    <div className='w-full h-full flex flex-col items-center justify-center bg-app-bg text-white left-0 z-10 pt-28'>
      <div className='w-full flex flex-col items-center sm:space-y-0 space-y-12'>
        <div className='w-full sm:hidden flex flex-col items-center'>
          <img className='min-w-[140px] object-contain' src="/images/dark/boxx.svg" alt="boxx logo" />
        </div>
        <LoginForm />
      </div>
    </div>
  )
}

export default Login