import React from 'react'

const SuccessIcon = () => {
  return (
    <div className='w-20 h-20'>
        <img src="/images/check-circle-green.png" alt="" />
    </div>
  )
}

export default SuccessIcon