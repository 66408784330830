import React from 'react'
import { cyberSafetyIntroSectionBtnText, cyberSafetyIntroSectionTitle } from '../../../helpers/constants';

const Introduction = (props) => {
    const { handleContentChange, description, moduleDescription, modules, guideLineContent } = props;
    return (
        <div className='w-full space-y-6 font-Roboto text-simple-gray'>
            <h1 className='text-base font-semibold'>{cyberSafetyIntroSectionTitle}</h1>
            <div className='w-full space-y-3 text-sm font-normal'>
                <p>{description}​</p>
                <div className='w-full space-y-3'>
                    <p>{moduleDescription}</p>
                    <ul className='list-disc list-inside pl-3'>
                        {modules.map((module,key)=>(
                            <li key={key}>{module}</li>

                        ))}
                    </ul>
                </div>
                <p>{guideLineContent}</p>
            </div>
            <div className='w-full pt-10 flex justify-center'>
                <button onClick={()=>handleContentChange('e-learnig','forward')} className='yellow-bg-btn'>{cyberSafetyIntroSectionBtnText}</button>
            </div>
        </div>
    )
}

export default Introduction