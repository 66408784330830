import React from 'react'

const FailedIcon = () => {
  return (
    <div className='w-20 h-20'>
        <img src="/images/x-circle-red.png" alt="faild-icon" />
    </div>
  )
}

export default FailedIcon