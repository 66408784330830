import React from 'react'
import { findScorePointerPosition } from '../../helpers/helper-functions';

const ScorePointer = ({ totalMarkScored, isSmallChart }) => {
    let pointer = findScorePointerPosition(totalMarkScored) ?? '';
    return (
        <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full'>
            <div className={`w-full transform duration-300`} style={{rotate:pointer}}>
                <div className={`${isSmallChart ? `triangle-down-small ` : `triangle-down`} -rotate-90 -ml-4`} />
            </div>
        </div>
    )
}

export default ScorePointer