import React from 'react'
import { cyberSafetyAssessmentCompletedMsg, cyberSafetyAssessmentCompletedSubMsg } from '../../../helpers/constants'

const AssessmentFinal = () => {
  return (
    <div className='w-full flex flex-col items-center text-center space-y-2 font-Roboto'>
        <h1 className='text-lg font-semibold text-extra-dark-gray'>{cyberSafetyAssessmentCompletedMsg}</h1>
        <div className='text-sm text-dark-gray font-normal w-52'>
            <p>{cyberSafetyAssessmentCompletedSubMsg}</p>
        </div>
    </div>
  )
}

export default AssessmentFinal