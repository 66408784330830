import React from 'react'
import { cyberSafetyELearningSectionBtnText, cyberSafetyELearningSectionTitle } from '../../../helpers/constants';

const ELearning = (props) => {
    const { handleContentChange, description, fileContent, video, currentPage } = props;
    return (
        <div className='w-full space-y-5 pb-6 font-Roboto text-simple-gray'>
            <h1 className='text-base font-semibold '>{cyberSafetyELearningSectionTitle}</h1>
            <p className='text-sm font-normal '>{description}</p>
            <div className='w-full space-y-3'>
                {fileContent.map((content, key) => (
                    <div key={key}>
                        <a href={`../files/pdf/${currentPage}/${content.file}`} target='_blank' rel="noreferrer" >
                            <div className='flex items-center space-x-3 text-lite-white text-xs tracking-.4px'>
                                <h2 className='uppercase'>{content.title}</h2>
                                <img src="/images/arrow-right-circle-down.png" alt="arrow down" />
                            </div>
                        </a>
                    </div>
                ))}
            </div>
            <div className='w-full pt-4'>
                <iframe src={video} title={`${currentPage}-video`} width="100%" className='aspect-video bg-black' allowFullScreen />
            </div>
            <div className='w-full pt-16 flex justify-center'>
                <button onClick={() => handleContentChange('assessment','forward')} className='yellow-bg-btn'>{cyberSafetyELearningSectionBtnText}</button>
            </div>
        </div>
    )
}

export default ELearning