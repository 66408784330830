import React, { useState } from 'react'
import { cyberSafetyAssessmentNextBtnText, cyberSafetyAssessmentPrevBtnText, cyberSafetyAssessmentSubmitBtnText } from '../../../helpers/constants';
import AssessmentFinal from './assessment-final';
import AssessmentHead from './assessment-head';
import AssessmentResult from './assessment-result';
import QuestionCard from './question-card';

const Assessment = (props) => {

    const { questions, passLimit, answerKey } = props;

    const [isResult, setIsResult] = useState(false);
    const [isResultVerified, setIsResultVerified] = useState(false);
    const [isQusetionsActive, setIsQuestionsActive] = useState(true);
    const [isPass, setIsPass] = useState(false);
    const [toggleValidatorMsg, setToggleValidatorMsg] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [totalCorrectAnswers, setTotalCorrectAnswers] = useState(0);
    const [answers, setAnswers] = useState({});
    let correctAnswers = 0;
    const passMsg = 'You’ve passed!';
    const failedMsg = `${passLimit} questions answered correctly is needed to pass the assessment.`;

    const handleAnswers = (answer) => {
        setAnswers((prevState) => ({
            ...prevState,
            [currentQuestion]: answer
        }))
    }

    /**
     * 
     * @param operaton indicates next or preview
     */
    const handleQuestions = (operaton) => {
        if (answers[currentQuestion] || operaton === 'previous') {
            if (operaton === 'previous' && currentQuestion !== 1) {
                setCurrentQuestion((prevState) => prevState - 1)
            } else if (operaton === 'next' && currentQuestion !== Object.values(questions).length) {
                setCurrentQuestion((prevState) => prevState + 1)
            }
        } else {
            setToggleValidatorMsg(true)
            let toggleTimer = setTimeout(() => {
                setToggleValidatorMsg(false)
                clearTimeout(toggleTimer)
            }, 2000);
        }
    }

    const totalQuestions = Object.values(questions).length;
    let totalAnsweredQuestions = Object.values(answers).length;

    const handleResult = () => {
        setIsResult(true)
        for (let i = 1; i <= Object.entries(answers).length; i++) {
            if (+answers[i] === answerKey[i]) {
                correctAnswers++;
            }
        }
        setIsPass(correctAnswers >= passLimit ? true : false)
        setIsResultVerified(true)
        setTotalCorrectAnswers(correctAnswers)
    }

    const handleRestartAssessment = () => {
        setIsResult(false);
        setIsQuestionsActive(true);
        setAnswers({});
        setTotalCorrectAnswers(0);
        totalAnsweredQuestions = 0;
        setIsPass(false);
        setIsResultVerified(false);
        setCurrentQuestion(1);
    }

    return (
        <div className='w-full'>
            {!isResult ?
                <div className='w-full'>
                    <AssessmentHead
                        currentQuestion={currentQuestion}
                        totalAnsweredQuestions={totalAnsweredQuestions}
                        totalQuestions={totalQuestions}
                    />
                    {isQusetionsActive ?
                        <div className='w-full py-8 space-y-5'>
                            <QuestionCard
                                handleAnswers={handleAnswers}
                                answers={answers}
                                currentQuestion={currentQuestion}
                                currentAssessment={questions[currentQuestion]}
                                toggleValidatorMsg={toggleValidatorMsg}
                            />
                            <div className={`w-full pt-8 flex justify-end ${currentQuestion !== 1 && `space-x-4`} `}>
                                {currentQuestion !== 1 && <button onClick={() => handleQuestions('previous')} className='yellow-border-btn'>{cyberSafetyAssessmentPrevBtnText}</button>}
                                <button onClick={() => currentQuestion === totalQuestions ? setIsQuestionsActive(false) : handleQuestions('next')} className='yellow-bg-btn'>{cyberSafetyAssessmentNextBtnText}</button>
                            </div>
                        </div> :
                        <div className='w-full'>
                            <div className='w-full pt-28 pb-32'>
                                <AssessmentFinal />
                            </div>
                            <div className='w-full flex justify-end space-x-4'>
                                <button onClick={() => setIsQuestionsActive(true)} disabled={currentQuestion === 1 ? true : false} className='yellow-border-btn'>{cyberSafetyAssessmentPrevBtnText}</button>
                                <button onClick={() => handleResult()} disabled={answers[currentQuestion] ? false : true} className='yellow-bg-btn'>{cyberSafetyAssessmentSubmitBtnText}</button>
                            </div>
                        </div>}
                </div>
                :
                <AssessmentResult
                    isResultVerified={isResultVerified}
                    isPass={isPass}
                    resultMsg={isPass ? passMsg : failedMsg}
                    description={`You answered ${totalCorrectAnswers}/${totalQuestions} questions correctly!`}
                    handleRestartAssessment={handleRestartAssessment}
                />
            }
        </div>
    )
}

export default Assessment