import React, { useEffect } from 'react'
import DynamicInnerPageWrapper from '../../components/cyber-training/dynamic-inner-page-wrapper'
import { cyberSecurityData } from '../../data/cyber-security-data'

const CyberSecurity = () => {
  useEffect(() => {
    window.scroll(0,0);
  }, [])
  const data = cyberSecurityData;
  return (
    <div className='w-full'>
      <DynamicInnerPageWrapper
        data={data}
      />
    </div>
  )
}

export default CyberSecurity