import React from 'react'
import ScoreWithRange from '../digital-risk-assessment/score-with-range';

import Chart from "react-apexcharts";
import ScorePointer from '../digital-risk-assessment/score-pointer';


const DoughnutChart = ({ data, options, chartWidth,handleContentChange, isSliced, range, isSmallChart, hasIndicator, totalMarkScored, userScoreRange }) => {
    return (
        <div className='w-full h-full relative rounded-full'>
            {(Object.values(data).length > 0 && Object.values(options).length > 0) &&
                <div className={`w-full h-full rounded-full overflow-hidden ${isSmallChart && `mt-4`} `}>
                    <Chart
                        series={data}
                        options={options}
                        type="donut"
                        width={chartWidth}
                    />
                </div>
            }
            {isSliced &&
                <div className={`w-full absolute bottom-0 text-extra-dark-gray font-semibold bg-app-bg flex justify-between ${isSmallChart ? `pl-4 pr-3.5 h-7` : `px-8 h-12`}`}>
                    <p className={`${isSmallChart ? `text-xxs` : `text-lg`}`}>{range[0]}</p>
                    <p className={` ${isSmallChart ? `text-xxs` : `text-lg`}`}>{range[1]}</p>
                </div>}
            {hasIndicator &&
                <ScorePointer
                    totalMarkScored={totalMarkScored}
                    isSmallChart={isSmallChart}
                />}
            <ScoreWithRange
                totalMarkScored={totalMarkScored}
                userScoreRange={userScoreRange}
                isSmallChart={isSmallChart}
                handleContentChange={handleContentChange}
            />
        </div>
    )
}

export default DoughnutChart