import React from 'react'
import GridWrapper from '../common/grid-wrapper'
import Card from './card'

import { cyberSafetyDataList as dataList } from '../../helpers/constants'

const ContentLlist = () => {
  return (
    <GridWrapper>
        {Object.entries(dataList).map((card)=>(
            <Card
                key={card[0]}
                header={card[1].header}
                image={card[1].image}
                description={card[1].description}
                link={card[1].path}
            />
        ))}
    </GridWrapper>
  )
}

export default ContentLlist