import React from 'react'

const DynamicTitle = ({ title, hasBorder }) => {
  return (
    <div className={`w-full flex justify-center text-center pb-3.5 ${hasBorder && `border-b border-extra-lite-gray`}`}>
      <h1 className='font-semibold font-Gibson text-xl max-w-350px text-extra-dark-gray'>{title}</h1>
    </div>
  )
}

export default DynamicTitle