export const sensitiveData = {
    pathName: 'sensitive-data',
    dynamicBanner: {
      img: 'bg-sensitive_data',
      title: 'Protecting Sensitive Data'
    },
    introduction: {
      description: 'Information is precious and critical to both individuals and organizations and needs to be protected.',
      moduleDescription: 'Learn more about keeping personal information safe including:',
      modules: ['Personal Information', 'GDPR & Data Protection'],
      guideLineContent: 'This module includes an educational video, downloadable information resources and an assessment (15 questions).'
    },
    eLearning: {
      description: `Please view the module's introductory video as well as the e-learning material.`,
      fileContent: [
        {
          title: 'DATA PROTECTION KEY POINTS',
          file: 'BOXX Academy - General Data Protection Regulation EN.pdf'
        },
        {
          title: 'PERSONAL INFORMATION KEY POINTS',
          file: 'BOXX Academy - Personal Information EN.pdf'
        },
      ],
      video: 'https://player.vimeo.com/video/814949396?h=93d99b8cea'
    },
    assessment: {
      questions: {
        1: {
          question: "Which is the MOST serious impact of having your personal information stolen from your computer or mobile device?",
          options: {
            1: "The inconvenience of checking my emails more often",
            2: "Criminals using my information to hack into sensitive accounts",
            3: "The inconvenience of restoring personal information to my device from a backup",
            4: "Notifying the police that my personal information has been stolen",
          },
        },
        2: {
          question: "Which is an example of sensitive personal information?",
          options: {
            1: "My date of birth",
            2: "My name",
            3: "My work address",
            4: "My job title",
          },
        },
        3: {
          question: "How should you protect personal information that you share on social media sites?",
          options: {
            1: "I should delete personal information as soon as my friends see it",
            2: "I should never share any personal information on social media sites",
            3: "I should use privacy settings to limit what I share, and who I share it with",
            4: "I don't need to take any action, because the site protects this information for me",
          },
        },
        4: {
          question: "How should you dispose of paper documents containing sensitive information?",
          options: {
            1: "Use the waste disposal unit for the local building",
            2: "Use a shredder to destroy the documents",
            3: "Rip up the documents and put them in the office waste bin",
            4: "Burn the documents in a safe location",
          },
        },
        5: {
          question: "What should you do FIRST if you suspect that a personal device containing sensitive information has been lost?",
          options: {
            1: "Identify what information has been lost",
            2: "Contact the person whose information it is",
            3: "Order a replacement device",
            4: "File a police report and notify of identity theft",
          },
        },
        6: {
          question: "How should you mail a portable hard drive containing sensitive data to another location?",
          options: {
            1: `Use a secure courier service so that the portable hard drive can't be stolen`,
            2: "Protect the portable hard drive with a strong password and provide the password separately",
            3: "Wrap the portable hard drive in bubble wrap to protect it from damage",
            4: "Send the portable hard drive in the post, the same way that you would send a letter",
          },
        },
        7: {
          question: "You receive a phone call from a business asking for personal information.  What should you do?",
          options: {
            1: "Provide the information that they need as they have used the correct phone number",
            2: "Ask them to confirm their name before providing any information",
            3: "Take the caller through an identity verification process",
            4: "Take their number and call them back",
          },
        },
        8: {
          question: "How should you safely share a password and sensitive data to a verified individual online?",
          options: {
            1: "I should include the password in the email with the confidential data",
            2: "I should send the password in a separate email to the confidential data",
            3: "I should text the password to the email recipient",
            4: "The email with the data should tell the verified individual to phone me and ask for the password",
          },
        },
        9: {
          question: "What should you NOT do when using public Wi-Fi?",
          options: {
            1: "Don't access your personal or financial information",
            2: "Stay permanently signed in to accounts to avoid inputting a password more than once",
            3: "Pay attention to warnings",
            4: "Stick to “HTTPS” websites",
          },
        },
        10: {
          question: "What is the definition of personal data?",
          options: {
            1: "Any data that can directly or indirectly identify a living person",
            2: "Any data that a person has on their phone",
            3: "Any data that a person shares on social media",
            4: "Any data that a person wants to keep private",
          },
        },
        11: {
          question: "Under the GDPR, what must an organization have to be able to process your personal data?",
          options: {
            1: "A business reason to process my data",
            2: "A public source from which they obtained my data",
            3: "A lawful basis for processing my data",
            4: "Permission from the police to process my data",
          },
        },
        12: {
          question: "Which is 'special category' personal data?",
          options: {
            1: "A person's full name",
            2: "A person's email address",
            3: "A person's credit card details",
            4: "A person's ethnic origin",
          },
        },
        13: {
          question: "An organization processing your data must ensure that your data...",
          options: {
            1: "is always saved in the cloud and encrypted.",
            2: "is always processed lawfully.",
            3: "is shared with other companies.",
            4: "is anonymized.",
          },
        },
        14: {
          question: "When using a USB stick to carry sensitive data, what should you do?",
          options: {
            1: "Keep the data on the USB stick until I'm sure I won't need it again",
            2: "Use a single USB stick for both sensitive and non-sensitive data",
            3: "Duplicate the sensitive data onto other USB sticks incase you misplace one",
            4: "Ensure that the data on the USB stick is encrypted",
          },
        },
        15: {
          question: "Which statement is INCORRECT?",
          options: {
            1: "Removable media can be useful for storing large quantities of information",
            2: "Using removable media inappropriately can result in a serious security incident",
            3: "Removable media is secure for storing and transferring information",
            4: "Removable media is a cost effective way to store information",
          },
        },
      },
      passLimit: 10,
      answerKey: {
        1: 2,
        2: 1,
        3: 2,
        4: 2,
        5: 4,
        6: 2,
        7: 3,
        8: 4,
        9: 2,
        10: 1,
        11: 3,
        12: 4,
        13: 2,
        14: 4,
        15: 2,
      }
    }
  }