import React, { useEffect } from 'react'
import HeadingText from '../../components/common/heading-text'
import { InnerHeader } from '../../components/common/inner-header'
import Details from '../../components/insurance-coverage/details'
import Footer from '../../components/insurance-coverage/footer'
import { insuranceCoverageTitle } from '../../helpers/constants'


const InsuranceCoverage = () => {
    useEffect(() => {
        window.scroll(0,0);
    }, [])
    return (
        <div className='w-full'>
            <div className='w-full'>
                <InnerHeader hasBackBtn={true} hasHomeBtn={true} />
            </div>
            <HeadingText title={insuranceCoverageTitle} />
            <Details />
            <Footer />
        </div>
    )
}

export default InsuranceCoverage