import React from 'react'
import { insuranceCoverageAmountDetails, InsuranceCoverageBajajAllianzTitle, InsuranceCoverageDifferentTypesOfAttack, insuranceCoverageFinancialLossesDetails, insuranceCoverageInstrumentsCovered, insuranceCoverageIntro, InsuranceCoverageMajorExclusions, InsuranceCoverageWhatBajajAllianzCovers } from '../../helpers/constants'
import { localStorageUtils } from '../../helpers/storageHelper'

const Details = () => {
    return (
        <div className='w-full flex justify-center font-Roboto'>
            <div className='w-full py-10 max-w-4xl border-b border-white border-opacity-20 space-y-7'>
                <div className='w-full space-y-8'>
                    <div className='w-full space-y-2'>
                        <p className='font-normal text-sm text-simple-gray leading-5'>{insuranceCoverageIntro}</p>
                    </div>
                    <div className='w-full space-y-4'>
                        <h1 className='font-normal text-base text-extra-dark-gray leading-6'>{insuranceCoverageAmountDetails} {localStorageUtils.getLocalStorageItem('insuranceCoverageAmount')}</h1>
                        <p className='font-normal text-sm text-simple-gray leading-5'>{insuranceCoverageInstrumentsCovered}</p>
                        <p className='font-normal text-sm text-simple-gray leading-5'>{insuranceCoverageFinancialLossesDetails}</p>
                        <ul className='pl-6 font-normal text-sm text-simple-gray leading-5 list-disc list-outside'>
                            {
                                Object.entries(InsuranceCoverageDifferentTypesOfAttack).map((attack) => (
                                    <li key={attack[0]}>{attack[1]}</li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className='w-full space-y-4'>
                        <h1 className='font-normal text-base text-extra-dark-gray leading-6 w-11/12'>{InsuranceCoverageBajajAllianzTitle}</h1>
                        {
                            Object.entries(InsuranceCoverageWhatBajajAllianzCovers).map((cover) => (
                                <div className='font-normal text-sm text-simple-gray leading-5' key={cover[0]}>
                                    <p className=''>{cover[1].title}</p>
                                    {cover[1].list &&
                                        <ol className='list-inside list-disc pl-2'>
                                            {Object.entries(cover[1].list).map((listItem) => (
                                                <li key={`${cover[0]}-${listItem[0]}`}>{listItem[1]}</li>
                                            ))}
                                        </ol>
                                    }
                                </div>
                            ))
                        }
                    </div>
                    {
                        Object.entries(InsuranceCoverageMajorExclusions).map((section) => (
                            <div className='w-full space-y-4' key={section[0]}>
                                <h1 className='font-normal text-base text-extra-dark-gray leading-6'>{section[1].title}</h1>
                                {
                                    <ul className='pl-6 font-normal text-sm text-simple-gray leading-5 list-disc list-outside'>
                                        {Object.entries(section[1].exclusions).map((exclusion) => (
                                            <li key={`${section[0]}-${exclusion[0]}`}>{exclusion[1]}</li>
                                        ))}
                                    </ul>
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Details