import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import api from '../../api/axiosConfig';
import { defaultConnectionErrorMsg, loginBtnText, loginInputCustomValidationText, loginInputValidationErrorText, loginPageInputPlaceholder, loginPageInputTitle, loginPageSubTitle, loginPageTitle, loginPageT_and_C_message, termsAndConditionLink } from '../../helpers/constants';
import { localStorageUtils } from '../../helpers/storageHelper';
import { isNumber } from '../../helpers/validator'
import LoaderEllipse from '../common/loader-ellipse';

const LoginForm = () => {

    const [userCridentials, setUserCridentials] = useState({
        phoneNumber: null
    })
    const [isValid, setIsValid] = useState(true)
    const navigate = useNavigate()
    const [isSearching, setIsSearching] = useState(false);

    const handleInputs = (form) => {
        if (form.target.name === 'phoneNumber') {
            if (isNumber(form.target.value)) {
                document.getElementById('validation_error').innerText = ''
                setUserCridentials((prevState) => ({
                    ...prevState,
                    [form.target.name]: form.target.value
                }))
                !isValid && setIsValid(true)
            } else {
                document.getElementById('validation_error').innerText = loginInputValidationErrorText
                setIsValid(false)
            }
        }
    }

    const handleLogin = (form) => {
        form.preventDefault();
        setIsSearching(true);
        api.post('user/auth', {
            phone: userCridentials.phoneNumber
        }).then(res => {
            if (res.status === 200) {
                localStorageUtils.setLocalStorageItem('token', res.data.data.accessToken)
                localStorageUtils.setLocalStorageItem('refreshToken', res.data.data.refreshToken)
                navigate('/boxx')
            }
        }).catch(err => {
            setIsSearching(false);
            if (err && err.response && err.response.data.message) {
                document.getElementById('validation_error').innerText = err.response.data.message
            } else {
                document.getElementById('validation_error').innerText = defaultConnectionErrorMsg
            }
            setIsValid(false)
            // document.getElementById('validation_error').innerText = err.response && err.response.data.message
        })

    }

    return (
        <form onSubmit={(e) => handleLogin(e)} className='w-full flex flex-col items-center'>
            <div className='w-72'>
                <div className='w-full '>
                    <div className='w-full text-xl font-normal text-strong-gray font-Gibson text-center'>
                        <h1>{loginPageTitle.message}</h1>
                        <h1>{loginPageTitle.product}</h1>
                    </div>
                    <p className='mt-5 text-sm font-normal font-Roboto text-medium-gray'>{loginPageSubTitle}</p>
                    <h1 className='mt-2 text-xs font-Roboto text-medium-gray font-normal'>{loginPageInputTitle}</h1>
                </div>
                <div className='w-full mt-2'>

                    <input type="tel" name="phoneNumber" value={userCridentials.phoneNumber ?? ''}
                        maxLength="10"
                        onInvalid={e => e.target.setCustomValidity(loginInputCustomValidationText)}
                        onInput={e => e.target.setCustomValidity('')}
                        autoComplete="off"
                        autoFocus
                        onChange={(e) => handleInputs(e)}
                        placeholder={loginPageInputPlaceholder}
                        className={`w-full relative rounded-md p-2 border ${!isValid ? 'border-err-input-border' : 'border-input-border'}  outline-none bg-transparent placeholder:text-lite-gray placeholder:text-xs placeholder:font-normal text-dark-gray`} required />

                </div>
                <div className="flex items-center justify-center pt-10">
                    <p className="text-xs text-dark-gray font-normal font-Roboto">
                        <Link to={termsAndConditionLink} target={'_blank'}>{loginPageT_and_C_message}</Link>
                    </p>
                </div>
                <p className={`mt-2 text-center text-sm text-err-text ${isValid ? `hidden` : `block`}`} id="validation_error"></p>
            </div>


            <div className='w-full flex flex-col items-center mt-4 pb-16'>
                <button disabled={isSearching} type="submit" className={`flex justify-center items-center rounded-lg w-52 text-center text-strong-gray font-Gibson font-medium h-9 ${isSearching ? 'bg-range-fill-bg py-1' : 'py-2'} border border-dark-yellow inline-block font-medium text-sm`}>
                    {isSearching ? <LoaderEllipse /> : loginBtnText}
                </button>
            </div>
        </form>
    )
}

export default LoginForm