export const digitalSafetyData = {
    pathName:'digital-safety',
    dynamicBanner:{
        img:'bg-digital_safety',
        title:'Digital Safety'
    },
    introduction:{
        description:'Online services are used by most of us for business and personal use. Understand the risks you face and sensible precautions to help keep you safe online.',
        moduleDescription:'Within this pathway are video animations, eLearning, summary document (PDF) and game learning modules covering:',
        modules:['Password Safety', 'Setting up multi-factor authentication (MFA)'],
        guideLineContent:'This module includes an educational video, downloadable information resources and an assessment (15 questions).'
    },
    eLearning:{
        description:`Please view the module's introductory video as well as the e-learning material.`,
        fileContent:[
            {
                title:'REMOTE & MOBILE WORKING KEY POINTS',
                file:'BOXX Academy - Remote and Mobile Working EN.pdf'
            },
            {
                title:'USING PERSONAL DEVICES KEY POINTS',
                file:'BOXX Academy - Using Personal Devices.pdf'
            },
        ],
        video:'https://player.vimeo.com/video/814949948?h=e74264fe45'
    },
    assessment:{
        questions:{
            1: {
                question: "If you find it difficult to remember strong passwords, it would help if you ...",
                options: {
                    1: "Downloaded a password manager",
                    2: "Wrote the passwords in a notebook",
                    3: "Use auto-logins on a web site",
                    4: "Send yourself an email containing your password",
                },
            },
            2: {
                question: "What is the minimum length for a strong password?",
                options: {
                    1: "6 characters",
                    2: "8 characters",
                    3: "10 characters",
                    4: "12 characters",
                },
            },
            3: {
                question: "Why should you be concerned about ransomware?",
                options: {
                    1: "It might expose my personal data on the internet",
                    2: "It might encrypt my data so that I can't access it",
                    3: "It might access my bank account and take mone",
                    4: "It might use all my network bandwidth",
                },
            },
            4: {
                question: "You have received an email from your bank asking you to log in to your account within a week. The email includes a logo that looks genuine and a link to access your account. What should you do?",
                options: {
                    1: "Follow the link and log in to my account, as the email looks genuine",
                    2: "Go to the bank's website by typing the URL manually, and log in to my account",
                    3: "Install antispam software to automatically block messages from people not in my contacts",
                    4: "Follow the link and enter false information to annoy the hackers, as this is likely to be phishing",
                },
            },
            5: {
                question: "Malware is...",
                options: {
                    1: "badly written software that can be annoying, but is not a security risk",
                    2: "malicious software that can have a big impact on my device or information",
                    3: "required software that is part of the operating system on my device",
                    4: "software that helps to protect my device from viruses and other threats",
                },
            },
            6: {
                question: "How can you be sure that all data you send to a website is encrypted?",
                options: {
                    1: `I should check that the status bar on my web browser shows a padlock icon`,
                    2: "I should use an encrypted web browser",
                    3: `I should look for the word "encrypted" in the first heading on the web page`,
                    4: "I should encrypt the data on my device before I connect to the website",
                },
            },
            7: {
                question: "What is the MOST important advice to follow to use social media safely?",
                options: {
                    1: "Do not use social media for work-related activities",
                    2: "Keep the number of posts you create each day to a minimum",
                    3: "Do not disclose sensitive personal or work information",
                    4: "Make sure you write posts in a clear and polite way",
                },
            },
            8: {
                question: "Which of these is a suitable password?",
                options: {
                    1: "P4ssw0r3",
                    2: "ui2$bW3U",
                    3: "myaccountsecret",
                    4: "gd!x$",
                },
            },
            9: {
                question: "Which of these is NOT a social media network?",
                options: {
                    1: "Email",
                    2: "LinkedIn",
                    3: "Twitter",
                    4: "Instagram",
                },
            },
            10: {
                question: "Someone has recently added you as a friend on a social networking site. They have asked you to post personal information on the site. What should you do?",
                options: {
                    1: "Write a public post asking for confirmation of the new friend's identity",
                    2: "Refuse to share the information and ask the new friend to verify their identity",
                    3: "Ask for the new friend's email address and send the information privately",
                    4: "Post the personal information on the site as requested",
                },
            },
            11: {
                question: "Why should you be careful about posting work-related information on a social networking site?",
                options: {
                    1: "Because social networking sites are not used for work purposes",
                    2: "Because other social network users are not interested in work-related information",
                    3: "Because it won't be kept up to date and will become inaccurate",
                    4: "Because it may be sensitive information which shouldn't be shared",
                },
            },
            12: {
                question: "What should you do when using social media?",
                options: {
                    1: "Use the privacy settings to make sure everyone can see my updates",
                    2: "Beware of suspicious links and attachments and don't click on them",
                    3: "Only report social media abuse when I'm absolutely sure it is intentional",
                    4: "Use a single password for all sites to make it easier to remember",
                },
            },
            13: {
                question: "Why are removable storage devices a threat to computer security?",
                options: {
                    1: "Removable storage devices are not a threat to security",
                    2: "Because they are housed inside a particular computer",
                    3: "Because they allow easy sharing of both data and viruses",
                    4: "Because they are centralized and accessible to everyone",
                },
            },
            14: {
                question: "What should users do to protect their devices before they get lost or stolen?",
                options: {
                    1: "Use passcodes/PINs to lock the screen",
                    2: "Enable phone-finding and remote-wiping capabilities",
                    3: "Set the screen to display the owner's contact information",
                    4: "All of the above",
                },
            },
            15: {
                question: "What is a secure data container?",
                options: {
                    1: "A lockbox where users can store their mobile devices",
                    2: "An authenticated and encrypted storage area on a device that holds sensitive files",
                    3: "A program or platform that requires a password to log-in",
                    4: "A password vault",
                },
            },
        },
        passLimit:10,
        answerKey:{
            1: 1,
            2: 2,
            3: 1,
            4: 2,
            5: 2,
            6: 2,
            7: 3,
            8: 2,
            9: 1,
            10: 2,
            11: 4,
            12: 2,
            13: 3,
            14: 4,
            15: 2,
        }
    }
}