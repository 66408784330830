import React from 'react'
import { breachContents, breachPopupCloseBtnText, breachPopupTitle } from '../../helpers/constants'

function SimpleModal({ handleClose }) {
  
  return (
    <div id="top-right-modal" data-modal-placement="top-right" tabIndex="-1" className="fixed top-0 left-0 w-full h-screen bg-blur-bg bg-opacity-60 px-8 py-28 overflow-y-auto flex justify-center md:items-center">
      <div className="w-full max-w-4xl overflow-y-auto h-min bg-app-bg py-7 px-5 space-y-8 font-Roboto shadow-[0px_25px_50px_-12px_rgba(39,39,54,0.25)]">
        <h1 className='text-xl.5 font-semibold text-extra-dark-gray leading-6'>{breachPopupTitle}</h1>
        {Object.entries(breachContents).map((breach) => (
          <div className='w-full space-y-2' key={breach[0]}>
            <h1 className='text-lg font-semibold leading-5 text-extra-dark-gray'>{breach[1].title}</h1>
            <p className='text-sm font-normal leading-4 text-medium-gray' dangerouslySetInnerHTML={{__html:breach[1].description}}/>
          </div>
        ))}
        <div className=''>
          <button className='text-lite-purple text-sm font-medium' onClick={handleClose}>
            <div className='flex items-center space-x-2'>
              <img src="/images/chevron-left.png" alt="uparrow" />
              <span className=''>{breachPopupCloseBtnText}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default SimpleModal