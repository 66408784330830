export const isNumber=(value)=>{
    const regexp = /^[0-9]*$/

    return regexp.test(value)
}

export const isMobile = () =>{
    let details = navigator.userAgent;
    const regexp = /Android|iPhone|kindle|iPad|Windows Phone/i;

    return regexp.test(details)
}