import React from 'react'
import DoughnutChart from '../charts/doughnut-chart'
import { draScoreSectionDescription, draScoreSectionLinkText, draScoreSectionTitle, draScoreSectionTitleDesription } from '../../helpers/constants'


const AssessmentScore = ({ chartData, chartOptions, scoreChartWidth, totalMarkScored, userScoreRange, handleContentChange, grandTotal, scoreStaringRange, currentContent }) => {
  return (
    <div className='w-full space-y-7 py-7 font-Roboto'>
      <div className='w-full space-y-3 text-simple-gray'>
        <h1 className=' font-semibold text-base'>{draScoreSectionTitle}</h1>
        <p className='text-sm font-normal'>{draScoreSectionTitleDesription}</p>
      </div>
      <div className='flex flex-col space-y-9'>
        <div className='w-full space-y-3'>
          <div className='w-full'>
            <div className='w-56 h-56 mx-auto rounded-full'>
              {chartData && chartOptions && grandTotal && totalMarkScored && userScoreRange &&
                <DoughnutChart
                  data={chartData}
                  options={chartOptions}
                  isSliced={true}
                  hasIndicator={true}
                  range={[scoreStaringRange, grandTotal]}
                  totalMarkScored={totalMarkScored}
                  userScoreRange={userScoreRange}
                  isSmallChart={false}
                  chartWidth={scoreChartWidth}
                  handleContentChange={handleContentChange}
                />}
            </div>
          </div>
          <div className='w-full flex justify-center'>
            <button onClick={() => handleContentChange('assessment result','forward')} className='text-base text-strong-gray font-semibold text-center underline'>View your results</button>
          </div>
        </div>
        <div className='w-full space-y-7'>
          <div className='w-full space-y-3 text-sm text-simple-gray font-normal'>
            {
              draScoreSectionDescription.map((description)=> <p key={description}>{description}</p>)
            }
          </div>
          <button onClick={() => handleContentChange('cyber security report','forward')} className='flex items-center space-x-2'>
            <p className='text-sm font-normal text-simple-gray'>{draScoreSectionLinkText}</p>
            <img src="/images/arrow-right-circle-fine.png" alt="arrow-right" className='h-5 w-5 object-contain' />
          </button>
        </div>
      </div>
    </div>
  )
}

export default AssessmentScore