import React from 'react'
import { Link } from 'react-router-dom'
import { draPageIntoBtnText, draPageIntoDescription, draPageIntoTitle } from '../../helpers/constants'

const Introduction = () => {
    return (
        <div className='w-full py-7 '>
            <div className='space-y-7 font-Roboto text-simple-gray'>
                <h1 className='font-semibold text-base leading-6'>{draPageIntoTitle}</h1>
                <p className='text-sm font-normal'>{draPageIntoDescription}</p>
            </div>
            <div className='w-full pt-32'>
                <div className='w-full flex justify-center'>
                    <Link to={'/digital_risk_assessment/assessment'}>
                        <button className='yellow-bg-btn'>
                            {draPageIntoBtnText}
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Introduction