import React from 'react'
import QuestionCard from './question-card'

const AssessmentWrapper = ({
    sectionId,
    handleAnswers,
    answers,
    toggleValidatorMsg,
    questions,
    questTitle,
}) => {
    return (
        <div className='w-full space-y-3'>
            <h1 className='text-base font-semibold text-extra-dark-gray'>{questTitle}</h1>
            <div className='w-full space-y-16'>
                {
                    Object.entries(questions).map((assessment) => (
                        <QuestionCard key={`${sectionId}-${assessment[0]}`}
                            handleAnswers={handleAnswers}
                            answers={answers}
                            currentQuestion={assessment[0]}
                            sectionId={sectionId}
                            currentAssessment={assessment[1]}
                            toggleValidatorMsg={toggleValidatorMsg}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default AssessmentWrapper