import React from 'react'

const AssessmentHead = (props) => {
    const { totalQuestions, totalAnsweredQuestions } = props;
    let progressBarStaus = (100 / totalQuestions) * totalAnsweredQuestions;
    return (
        <div className='w-full space-y-2 font-Roboto'>
            <div className='w-full h-1.5 bg-input-border rounded-3xl'>
                <div className={`h-full rounded-3xl bg-range-fill-bg transform duration-300`} style={{ width: `${progressBarStaus}%` }} />
            </div>
            <h2 className='text-sm font-normal text-medium-gray'>{`Question ${totalAnsweredQuestions}/${totalQuestions} completed`}</h2>
        </div>
    )
}

export default AssessmentHead