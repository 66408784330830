import React from 'react'

const ScoreWithRange = ({ totalMarkScored, handleContentChange, userScoreRange, isSmallChart }) => {
  return (
    <div className={`absolute left-1/2 transform -translate-x-1/2 ${isSmallChart ? `-space-y-2 top-8` : `top-1/2 space-y-2 -translate-y-1/2`} font-semibold text-center font-Gibson`}>
      <h1 className={`${isSmallChart ? `text-3xl` : `text-5xl`} ${userScoreRange === 'POOR' ? `text-err-input-border` : userScoreRange === 'GOOD' ? `text-extra-dark-yellow ` : `text-success`} `}>{totalMarkScored}</h1>
      <button onClick={() => handleContentChange('assessment result','forward')}>
        <h2 className={`${isSmallChart ? `text-xl` : `text-4xl underline`} text-strong-gray`}>{userScoreRange}</h2>
      </button>
    </div>
  )
}

export default ScoreWithRange