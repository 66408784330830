import React from 'react'
import { Link } from 'react-router-dom';
import { cyberSafetyCardBtnText, cyberSafetyCommonModlueDetails } from '../../helpers/constants';

const Card = (props) => {
    const { header, image, description, link } = props;
    return (
        <div className='w-full rounded-xl pl-5 pr-2 py-2.5 space-y-3 border-.5 border-dark-blue font-Roboto bg-light-blue'>
            <h1 className='text-lg text-extra-dark-gray font-normal'>{header}</h1>
            <div className='flex items-start space-x-3'>
                <div className='w-75px h-75px min-w-max'>
                    <img src={`/images/${image}`} alt={image} />
                </div>
                <div className='w-full space-y-2 flex flex-col justify-between'>
                    <div className='space-y-3'>
                        <p className='text-xs font-normal text-medium-gray'>{description}</p>
                        <p className='text-xs font-normal text-medium-gray'>{cyberSafetyCommonModlueDetails}</p>
                    </div>
                    <div className='inline-block'>
                        <Link to={`/cyber_training${link !== "" ? `/${link}` : ``}`}>
                            <div className='flex items-center space-x-3'>
                                <p className='font-normal text-xs text-simple-gray uppercase'>{cyberSafetyCardBtnText}</p>
                                <img src={`/images/arrow-right-circle-small.png`} alt={'arrow circle'} />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card