import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { authUtils } from '../../helpers/authHelper';
import SideNav from '../navbar/sideNav';

const Header = () => {
    const [isSideNav, setIsSideNav] = useState(false)

    const toggleSideNav = () => {
        setIsSideNav(!isSideNav);
    }
    let location = useLocation();

    const isAuthenticated = authUtils.isAuthenticated()
    return (
        <div className={`w-full bg-header-bg rounded-b-xl ${location.pathname === '/' ? `py-7` : `py-4`}`}>
            <div className={`w-full grid grid-cols-3`}>
                <div className='md:flex md:justify-start md:items-center'>
                    <div className='hidden px-5 md:flex md:justify-start md:items-center'>
                    {
                        location.pathname === '/'
                        ? <img className={`w-36 md:ml-8`} src="/images/light/boxx.svg" alt="housing logo" />
                        : <Link to={'/boxx'}>
                        <img className={`w-36 md:ml-8`} src="/images/light/boxx.svg" alt="housing logo" />
                    </Link>
                    }
                    </div>
                </div>
                <div className='flex items-center justify-center'>
                    {
                        location.pathname === '/'
                        ? <img className={`w-40 md:w-80`} src="/images/Housing.com_logo.svg" alt="housing logo" />
                        : <Link to={'/boxx'}>
                        <img className={`w-40 md:w-80`} src="/images/Housing.com_logo.svg" alt="housing logo" />
                    </Link>
                    }
                </div>
                <div className='md:flex md:justify-end md:items-center'>
                    {isAuthenticated && <div className='hidden px-5 md:flex md:justify-end md:items-center'>
                        <button onClick={() => toggleSideNav()} className='min-w-max flex items-center justify-end'>
                            <img className='w-12 md:mr-8 ' src="/images/user-icon.svg" alt="user-icon" />
                        </button>
                    </div>}
                </div>
                <SideNav
                    isSideNav={isSideNav}
                    toggleSideNav={toggleSideNav}
                />
            </div>
        </div>
    )
}

export default Header