import React from 'react'
import { cyberSafetySectionHandleErrMsg } from '../../helpers/constants'
import Assessment from './inner-page-components/assessment'
import ELearning from './inner-page-components/e-learning'
import Introduction from './inner-page-components/introduction'

const SectionChooser = ({ section, data, handleContentChange }) => {
    switch (section) {
        case 'introduction':
            return (
                <Introduction
                    handleContentChange={handleContentChange}
                    description={data.introduction.description}
                    moduleDescription={data.introduction.moduleDescription}
                    modules={data.introduction.modules}
                    guideLineContent={data.introduction.guideLineContent}
                />
            )
        case 'e-learnig':
            return (
                <ELearning
                    handleContentChange={handleContentChange}
                    description={data.eLearning.description}
                    fileContent={data.eLearning.fileContent}
                    video={data.eLearning.video}
                    currentPage={data.pathName}
                />
            )
        case 'assessment':
            return (
                <Assessment
                    questions={data.assessment.questions}
                    passLimit={data.assessment.passLimit}
                    answerKey={data.assessment.answerKey}
                />
            )

        default: return <h1 className='font-semibold font-Roboto text-extra-dark-gray'>{cyberSafetySectionHandleErrMsg}</h1>
    }
}

export default SectionChooser