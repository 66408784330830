import React from 'react'
import { Navigate, useLocation } from 'react-router'
import Header from '../../components/check-for-breaches/header';
import HousingHeader from '../../components/common/housing-header';

function CheckBreachesDetails() {
  const location = useLocation();
  if (!location.state) {
    return <Navigate to={'/check_for_breaches'} />
  }

  const data = location.state.data;
  return (
    <>
      <div className='w-full'>
        <div className='w-full'>
          <HousingHeader
            hasBackBtn={true}
            logoPosition={'end'}
            hasBorder={false}
          />
          <Header />
        </div>
        <div className='flex justify-center'>
          <div className='mt-5 w-full max-w-4xl py-8 rounded-xl px-5 space-y-3'>
            <div className='mb-5'>
              <h1 className='text-xl font-semibold text-white'>{data.Domain || data.Name}</h1>
            </div>
            <div className='mb-5'>
              <h1 className='text-lg font-semibold text-white'>Overview</h1>
              <span className='text-sm m-0 text-dark-gray'>On {data.BreachDate}, {data.Name} was breached.</span><br />
            </div>
            <div className='mb-5'>
              <h1 className='text-lg font-semibold text-white'>What data was compromised:</h1>
              <div className='pl-5'>
                <ul className="list-disc">
                  {
                    data.DataClasses.map((_class, index) => (<li key={`dt-cl-${index}`} className="text-sm m-0 text-dark-gray">{_class}</li>))
                  }
                </ul>
              </div>
            </div>
            <div className='mb-5'>
              {/* <h1 className='text-lg font-semibold text-white font-bold'>What to do for this breach:</h1> */}
              <span className='text-sm m-0 text-dark-gray' dangerouslySetInnerHTML={{ __html: '<span class="[&>a]:text-dark-yellow">' + data.Description + '</span>' }}></span><br />

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckBreachesDetails