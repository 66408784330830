import React from 'react'
import { Outlet, useLocation } from "react-router-dom";
import Footer from '../common/footer';
import Header from '../common/header';
import { localStorageUtils } from '../../helpers/storageHelper';

const Layout = () => {
  
  const location = useLocation();
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const plan = searchParams.get('plan');
  if (plan) {
    localStorageUtils.setLocalStorageItem('url_plan', plan);
  }
 
  return (
    <div className={`flex flex-col w-full bg-app-bg min-h-screen relative overflow-y-auto`}>
        <Header/>
        <div className={`w-full px-6 flex-grow`}>
            <Outlet />
        </div>
        <Footer />
    </div>
  )
}

export default Layout