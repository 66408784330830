import React from 'react'
import { Link } from 'react-router-dom'
import { boxxinsuranceLink, boxxinsuranceText, footerDescription, footerTitle, privacypolicyLink, privacypolicyText, termsAndConditionLink, termsAndConditionText } from '../../helpers/constants'

function Footer() {
  return (
    <footer className='bg-footer-sm-bg px-2 sm:px-4 md:px-8 py-3 md:py-7 w-full'>
      <div className='flex lg:items-end items-center justify-between'>
        <div className='h-full'>
          <p className='text-footer-text font-semibold text-base md:text-xl lg:text-2xl xl:text-3xl sm:text-[17px] xs:leading-[17px] text-dark-gold font-Gibson'>{footerTitle}</p>
          <p className='text-xxs md:text-base font-normal text-light-white font-Roboto'>{footerDescription}</p>
        </div>
        <div className='flex lg:flex-row flex-col h-full lg:space-x-6 text-center font-normal text-xxs md:text-base text-light-white font-Roboto'>
          <p className='lg:underline'><Link to={termsAndConditionLink} target={'_blank'}>{termsAndConditionText}</Link></p>
          <p className='lg:underline'><Link to={privacypolicyLink} target={'_blank'}>{privacypolicyText}</Link></p>
          <p className='underline'><Link to={boxxinsuranceLink} target={'_blank'}>{boxxinsuranceText}</Link></p>
        </div>
      </div>

    </footer>
  )
}

export default Footer