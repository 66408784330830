import React, { useEffect } from 'react'

import DynamicInnerPageWrapper from '../../components/cyber-training/dynamic-inner-page-wrapper'
import { digitalSafetyData } from '../../data/digital-safety-data'

const DigitalSafety = () => {
    useEffect(() => {
        window.scroll(0,0);
    }, [])
    const data = digitalSafetyData;
    return (
        <div className='w-full'>
            <DynamicInnerPageWrapper
                data={data}
            />
        </div>
    )
}

export default DigitalSafety