import React from 'react'
import { cyberSafetyAssessmentResultLoadingMsg } from '../../../helpers/constants';
import ResultPageBtns from './result-page-btns'
import ResultWithMsg from './result-with-msg'

const AssessmentResult = (props) => {
  const { isPass, resultMsg, description, isResultVerified, handleRestartAssessment } = props;
  return (
    <div className='w-full py-10'>
      {isResultVerified ?
      <div className='w-full space-y-12'>
        <ResultWithMsg
          isPass={isPass}
          resultMsg={resultMsg}
          description={description}
        />
        <ResultPageBtns
          isPass={isPass}
          handleRestartAssessment={handleRestartAssessment}
        />
      </div> :
      <h1 className='text-simple-gray font-semibold text-lg text-center'>{cyberSafetyAssessmentResultLoadingMsg}</h1>}
    </div>
  )
}

export default AssessmentResult