import React from 'react'
import DoughnutChart from '../charts/doughnut-chart'
import { draResultClasification, draResultSectionLinkText, draResultSectionTitle, draResultSectionTitleDescription } from '../../helpers/constants'

const AssessmentResult = ({ chartData, chartOptions, resultChartWidth, totalMarkScored, userScoreRange, handleContentChange, grandTotal, scoreStaringRange, currentContent }) => {
  return (
    <div className='w-full space-y-7 py-7 font-Roboto'>
      <div className='flex items-end space-x-5'>
        <div className='space-y-5 text-simple-gray'>
          <h1 className='text-base font-semibold '>{draResultSectionTitle}</h1>
          <p className='text-sm font-normal '>{draResultSectionTitleDescription}</p>
        </div>
        <div className='w-32 h-32 mx-auto rounded-full'>
          <DoughnutChart
            data={chartData}
            options={chartOptions}
            isSliced={true}
            hasIndicator={true}
            range={[scoreStaringRange, grandTotal]}
            totalMarkScored={totalMarkScored}
            userScoreRange={userScoreRange}
            isSmallChart={true}
            chartWidth={resultChartWidth}
          />
        </div>
      </div>
      <div className='w-full space-y-5'>
        {
          Object.entries(draResultClasification).map((result) => (
            <div className='w-full space-y-2.5' key={result[1].type}>
              <div className='flex items-center space-x-2.5'>
                <p className={`h-3.5 w-3.5 rounded-full ${result[1].type === 'poor' ? `bg-err-input-border` : result[1].type === 'good' ? `bg-warning` : `bg-success`} min-w-max`}></p>
                <h1 className={`${result[1].type === 'poor' ? `text-err-input-border` : result[1].type === 'good' ? `text-extra-dark-yellow` : `text-success`} text-lg font-normal font-Gibson pt-1`}>{result[1].title}</h1>
              </div>
              <p className='text-sm font-normal text-simple-gray'>{result[1].description}</p>
            </div>
          ))
        }
      </div>
      <button onClick={() => handleContentChange('cyber security report', 'forward')} className='flex items-center space-x-2'>
        <p className='text-sm font-normal text-simple-gray'>{draResultSectionLinkText}</p>
        <img src="/images/arrow-right-circle-fine.png" alt="arrow-right" className='h-5 w-5 object-contain' />
      </button>
    </div>
  )
}

export default AssessmentResult