import React from 'react'

const Generator = ({ inputType, data, handleAnswers, answers, sectionId, questionId, answerTypeId }) => {
    let uniqueId;
    switch (inputType) {
        case 'checkbox':
            return (
                <div className='w-full space-y-3'>
                    {Object.entries(data).map((option) => {
                        uniqueId =  `${sectionId}-${questionId}-${answerTypeId}-${option[0]}`;
                        return (
                            <div className='w-full flex items-center space-x-2' key={uniqueId}>
                                <input type="checkbox" checked={answers?.[sectionId]?.[questionId]?.[answerTypeId]?.includes(option[0]) ? true : false} onChange={() => handleAnswers(option[0], 'multi', questionId, answerTypeId)} name={questionId} id={uniqueId} className='custom-checkbox peer' />
                                <label htmlFor={uniqueId} className='peer-disabled:cursor-default cursor-pointer text-sm font-normal font-Roboto text-dark-gray'>{option[1].name}</label>
                            </div>
                        )
                    })}
                </div>
            )
        case 'radio':
            return (
                <div className='w-full space-y-3'>
                    {Object.entries(data).map((option) => {
                        uniqueId =  `${sectionId}-${questionId}-${answerTypeId}-${option[0]}`;
                        return (
                            <div className='w-full flex items-center space-x-2' key={uniqueId}>
                                <input type="radio" checked={answers?.[sectionId]?.[questionId]?.[answerTypeId]?.includes(option[0]) ? true : false} onChange={() => handleAnswers(option[0], 'single', questionId, answerTypeId)} name={`${sectionId}-${questionId}-${answerTypeId}`} id={uniqueId} className='custom-radio-btn peer' />
                                <label htmlFor={uniqueId} className='peer-disabled:cursor-default cursor-pointer text-sm font-normal font-Roboto text-dark-gray'>{option[1].name}</label>
                            </div>
                        )
                    })}
                </div>
            )
        case 'select':
            return (
                <div className='w-full max-w-sm'>
                    <select value={answers?.[sectionId]?.[questionId]?.[answerTypeId]?.[0] ?? ''} onChange={(e) => handleAnswers(e.target.value, 'single', questionId, answerTypeId)} className='w-full border border-input-border rounded-lg p-2 cursor-pointer text-sm font-normal font-Roboto text-dark-gray outline-none'>
                        <option value="">------ select ------</option>
                        {Object.entries(data).map((option) => {
                            uniqueId =  `${sectionId}-${questionId}-${answerTypeId}-${option[0]}`;
                            return(
                                <option value={option[0]} key={uniqueId}>{option[1].name}</option>
                            )
                        })}
                    </select>
                </div>
            )

        default: return <h1 className='text-err-input-border'>invalid input type</h1>
    }
}

export default Generator