import React from 'react'
import { cyberReport } from '../../helpers/constants'

const CyberSecurityReport = ({handleContentChange}) => {

  
  return (
    <div className='w-full py-7 space-y-10 font-Roboto'>
      <div className='w-full'>
        <h1 className='text-base font-semibold text-simple-gray'>How We Determine Your Security Scorecard:</h1>
      </div>
      <div className='w-full space-y-6'>
        {Object.entries(cyberReport).map((content) => (
          <div className='w-full space-y-4' key={content[0]}>
            <div className='flex items-center space-x-3'>
              <img src="/images/alert-octagon.png" alt="esclamation-icon" />
              <h1 className='text-base font-normal text-extra-dark-gray'>{content[1].title}</h1>
            </div>
            <p className='text-sm font-normal text-simple-gray'>{content[1].description}</p>
          </div>
        ))}
      </div>
      <button onClick={()=>handleContentChange('assessment score','forward')} className='flex items-center space-x-3'>
        <img src="/images/chevron-left.png" alt="left-arrow" />
        <p className='text-lite-purple font-medium text-sm'>Back to results</p>
      </button>
    </div>
  )
}

export default CyberSecurityReport