import React from 'react'
import { draNextBtnText, draPreviousBtnText } from '../../helpers/constants'
import AssessmentHead from './assessment-head'
import AssessmentWrapper from './assessment-wrapper'

const Assessment = (props) => {
  const {
    currentSection,
    totalAnsweredQuestions,
    totalQuestions,
    assessments,
    answers,
    handleAnswers,
    handleQuestions,
    toggleValidatorMsg,
  } = props
  return (
    <div className='w-full space-y-9 font-Roboto'>
      <div className='w-full pt-7'>
        <AssessmentHead
          totalAnsweredQuestions={totalAnsweredQuestions}
          totalQuestions={totalQuestions}
        />
      </div>
      <div className='w-full py-8 space-y-10'>
        <AssessmentWrapper
          sectionId={currentSection}
          questions={assessments?.[currentSection]?.questions ?? {}}
          questTitle={assessments?.[currentSection]?.title ?? ''}
          handleAnswers={handleAnswers}
          answers={answers}
          toggleValidatorMsg={toggleValidatorMsg}
        />
        <div className={`w-full pt-8 flex justify-end ${currentSection !== 1 && `space-x-4`} `}>
          {currentSection !== 1 && <button onClick={() => handleQuestions('previous')} className='yellow-border-btn'>{draPreviousBtnText}</button>}
          <button onClick={() => handleQuestions('next')} className='yellow-bg-btn'>{draNextBtnText}</button>
        </div>
      </div>
    </div>
  )
}

export default Assessment