import React, { useState } from 'react'
import SideNav from '../navbar/sideNav';

const Header = () => {
    const [isSideNav, setIsSideNav] = useState(false)

    const toggleSideNav = () => {
        setIsSideNav(!isSideNav);
    }
    return (
        <div className='w-full flex justify-between border-b border-white border-opacity-20 pt-3 pb-5'>
            <div className='w-75px relative flex items-center justify-start pt-1'>
                <img src="/images/boxx_logo.png" alt="boxx logo" />
            </div>
            <h1 className='font-semibold text-xl min-w-max text-white sm:text-center'>Check for Breaches</h1>

            <button onClick={() => toggleSideNav()} className=''>
                <img src="/images/user-icon.png" alt="user" />
            </button>
            <SideNav
                isSideNav={isSideNav}
                toggleSideNav={toggleSideNav}
            />
        </div>
    )
}

export default Header