import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SimpleModal from '../common/simple-modal';
import Card from "./card";

const BreachResults = ({data}) => {

    const [modalContent, setModalContent] = useState(null);

    const handleLearnMore = (data) => {
        setModalContent(data);
    }

    useEffect(() => {
        if (modalContent) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "auto";
        }
    }, [modalContent]);

    useEffect(() => {
        return () => {
            document.body.style.overflowY = "auto";
        }
    }, []);

    const handleClose = () => {
        setModalContent(null);
    }
    return <div className="flex justify-center">
        <div className='w-full max-w-4xl my-7'>
        <div className=''>
            <h1 className='text-lg text-center font-semibold text-simple-gray font-Roboto'>Results for: <span className="text-medium-purple">{data && data.search_data}</span></h1>
            {
                data && data.search_result && data.search_result.length > 0 &&
                <p className="text-center text-simple-gray text-sm mt-3 font-Roboto">This {data && data.type} appeared in <span className="text-err-input-border font-Roboto">{data.search_result.length}</span> known data breaches:</p>
            }
        </div>

        {
            data && data.search_result && data.search_result.length === 0 &&
            <div className='flex flex-col items-center m-[0px]'>
                <div className='p-[60px_50px_40px_50px]'>
                <p className="text-center text-base text-simple-gray leading-5 font-Roboto">This {data && data.type} appeared in <span className="text-success text-input-border-destructive">0</span> known data breaches!</p>

                </div>
                <Link to="/boxx">
                    <button className='min-w-[153px] py-2 text-sm font-semibold text-extra-dark-gray leading-4 bg-dark-yellow rounded-lg font-Roboto'>Return to home</button>

                </Link>
                <Link to="/check_for_breaches">
                    <button className='mt-7 min-w-[153px] py-2 text-sm font-semibold text-extra-dark-gray leading-4 bg-app-bg border border-dark-yellow rounded-lg font-Roboto'>Run another search</button>

                </Link>
            </div>
        }

        <div className='mt-10 space-y-3'>
        {data && data.search_result.map((breach, index) => {
            return <Card key={`breach-${index}`} data={breach} handleLearnMore={handleLearnMore} />
        })}
        </div>

        {
            modalContent &&
            <SimpleModal handleClose={handleClose} />
        }

    </div>
    </div>
}

export default BreachResults;